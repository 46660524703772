// react imports
import React, { useState } from "react";

// prime components
import { Button } from "primereact/button";

// import custom components
import ActivitiesList from "./activities-list/ActivitiesList";
import ActivitiesFilterPanel from "./activities-filter-panel/ActivitiesFilterPanel";
import CreateActivityModal from "../common/modals/create-activity-modal/CreateActivityModal";

// sample data
import { ACTIVITIES } from "../../apis/sampleData";

// style
import "./ActivitiesPage.scss";

const ActivitiesPage = () => {
  // states
  const [activities, setActivities] = useState(ACTIVITIES);
  const [createActivityModalVisible, setCreateActivityModalVisible] = useState(false);

  // events
  const assigningToProjectHandler = activityId => {
    setActivities(prevActivities => {
      const newActivities = [...prevActivities];


      newActivities[newActivities.map(a => a.id).indexOf(activityId)].assignedToProject = true;

      return newActivities;
    });
  };
  const removingFromProjectHandler = activityId => {
    setActivities(prevActivities => {
      const newActivities = [...prevActivities];

      newActivities[newActivities.map(a => a.id).indexOf(activityId)].assignedToProject = false;

      return newActivities;
    });
  };
  const linkingToRecommendationHandler = activityId => {
    setActivities(prevActivities => {
      const newActivities = [...prevActivities];

      newActivities[newActivities.map(a => a.id).indexOf(activityId)].linkedToRecommendation = true;

      return newActivities;
    });
  };
  const unlinkingFromRecommendationHandler = activityId => {
    setActivities(prevActivities => {
      const newActivities = [...prevActivities];

      newActivities[newActivities.map(a => a.id).indexOf(activityId)].linkedToRecommendation = false;

      return newActivities;
    });
  };
  const clickOnCreateActivity = () => {
    setCreateActivityModalVisible(true);
  };
  const createActivityModalCloseHandler = () => {
    setCreateActivityModalVisible(false);
  };



  return (
    <div className="page-wrapper activities">
      <CreateActivityModal
        onSave={createActivityModalCloseHandler}
        onClose={createActivityModalCloseHandler}
        modalVisible={createActivityModalVisible}
      />

      <Button
        icon="fas fa-clipboard-check"
        label="Create Activity"
        onClick={clickOnCreateActivity}
        className="create-activity"
      />

      <ActivitiesFilterPanel />

      <ActivitiesList
        activities={activities}
        activityClickable={true}
        sortBy="IMPLEMENTATION STATUS"
        exportAvailable={true}
        multipleAddingAvailable={false}
        multipleAddingLinkText={"add all (#NUMBERS) to project"}
        multipleAddingHandler={activityIDs => console.log("multiple add handler", activityIDs)}
        assignToProjectLinkAvailable={true}
        assigningToProjectHandler={assigningToProjectHandler}
        removingFromProjectHandler={removingFromProjectHandler}
        linkToRecommendationLinkAvailable={true}
        linkingToRecommendationHandler={linkingToRecommendationHandler}
        unlinkingFromRecommendationHandler={unlinkingFromRecommendationHandler}
      />
    </div>
  );
};

export default ActivitiesPage;