// react imports
import { useState } from "react";

// prime react imports
import { TabView, TabPanel } from 'primereact/tabview';

// statitics pages
import SPRecommendations from "./statistics-pages/recommendations/SPRecommendations";
import SPFollowupPLanning from "./statistics-pages/followup-planning/SPFollowupPLanning";

// styles
import './Statistics.scss';
import SPImplementation from "./statistics-pages/implementation/SPImplementation";

const Statistics = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="statistics page-wrapper white-bc">
      <TabView className="big-blue" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Recommendations">
          <SPRecommendations />
        </TabPanel>
        <TabPanel header="Follow-up planning">
          <SPFollowupPLanning />
        </TabPanel>
        <TabPanel header="Implementation progress">
          <SPImplementation />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Statistics;