// react imports
import React, { useState } from "react";

// prime react components
import { Button } from "primereact/button";

// custom components
import StatisticsChart from "../statistics-chart/StatisticsChart";

// consts
import { COLORS } from "../../../../apis/constants";

const SPFollowupPLanning = () => {
  const data = [
    {
      label: 'SR Water & sanitation',
      types: [
        { label: 'Assigned', value: 6, backgroundColor: COLORS.primaryBlue },
        { label: 'Unassigned', value: 4, backgroundColor: COLORS.lightBlue }
      ]
    },
    {
      label: 'SR Leprosy',
      types: [
        { label: 'Assigned', value: 3, backgroundColor: COLORS.primaryBlue },
        { label: 'Unassigned', value: 3, backgroundColor: COLORS.lightBlue }
      ]
    },
    {
      label: 'SR IDPs',
      types: [
        { label: 'Assigned', value: 6, backgroundColor: COLORS.primaryBlue },
        { label: 'Unassigned', value: 12, backgroundColor: COLORS.lightBlue }
      ]
    },
    {
      label: 'Special Rapporteur on the situation of human rights in the Syrian Arab Republic',
      types: [
        { label: 'Assigned', value: 1, backgroundColor: COLORS.primaryBlue },
        { label: 'Unassigned', value: 2, backgroundColor: COLORS.lightBlue }
      ]
    },
    {
      label: 'SR Water & sanitation',
      types: [
        { label: 'Assigned', value: 5, backgroundColor: COLORS.primaryBlue },
        { label: 'Unassigned', value: 3, backgroundColor: COLORS.lightBlue }
      ]
    },
  ];

  const [activeTab, setActiveTab] = useState('sdgs');

  return (
    <div className="statistics-page">
      <Button
        icon="las la-external-link-alt"
        label="Export"
        className="p-button-text p-button-secondary"
        onClick={() => console.log('Export')}
      />

      <h2>
        Lorem ipsum dolor sit amet
      </h2>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam velit magna, molestie eget lobortis et, sollicitudin non lacus. Donec accumsan arcu eu erat congue pretium pretium sed tellus.
      </p>

      <div className="sp--charts">
        <div className="sp--charts--tablist">
          <div className={activeTab === 'sdgs' ? 'active' : ''} onClick={() => setActiveTab('sdgs')}>
            <title>SDGs</title>
            <div>
              <i className="las la-list-alt"></i>
              23/24
            </div>
            <div className="tab--progress-bar">
              <div style={{ width: (23 / 24) * 100 + '%' }}></div>
            </div>
          </div>
          <div className={activeTab === 'themes' ? 'active' : ''} onClick={() => setActiveTab('themes')}>
            <title>Themes</title>
            <div>
              <i className="las la-list-alt"></i>
              14/35
            </div>
            <div className="tab--progress-bar">
              <div style={{ width: (14 / 35) * 100 + '%' }}></div>
            </div>
          </div>
          <div className={activeTab === 'affected-persons' ? 'active' : ''} onClick={() => setActiveTab('affected-persons')}>
            <title>Affected persons</title>
            <div>
              <i className="las la-list-alt"></i>
              4/12
            </div>
            <div className="tab--progress-bar">
              <div style={{ width: (4 / 12) * 100 + '%' }}></div>
            </div>
          </div>
        </div>
        <div className="sp--charts--tabcontent">
          {(activeTab === 'sdgs') && <StatisticsChart title='SDGs' data={data} />}
          {(activeTab === 'themes') && <StatisticsChart title='Themes' data={data} />}
          {(activeTab === 'affected-persons') && <StatisticsChart title='Affected Persons' data={data} />}
        </div>
      </div>
    </div>
  );
};

export default SPFollowupPLanning;