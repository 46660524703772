// react imports
import React, { useState } from "react";

// prime react components
import { Button } from "primereact/button";

// custom components
import StatisticsChart from "../statistics-chart/StatisticsChart";

// consts
import { COLORS } from "../../../../apis/constants";

const SPImplementation = () => {
  const data = [
    {
      label: 'Equality & non-discrimination',
      types: [
        { label: 'Not started', value: 6, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 4, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 2, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum dolor sit amet eripteum sit dolor',
      types: [
        { label: 'Not started', value: 3, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 3, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 4, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'Not started', value: 6, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 12, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 2, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum amet',
      types: [
        { label: 'Not started', value: 1, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 2, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 4, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'Not started', value: 5, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 3, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 6, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum dolor sit',
      types: [
        { label: 'Not started', value: 8, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 1, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 1.5, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'Not started', value: 2, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 8, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 2.5, backgroundColor: COLORS.primaryGreen }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'Not started', value: 11, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 0, backgroundColor: COLORS.lightGreen },
        { label: 'Treaty bodies', value: 2, backgroundColor: COLORS.primaryGreen }
      ]
    }
  ];

  const [activeTab, setActiveTab] = useState('projects');

  return (
    <div className="statistics-page">
      <Button
        icon="las la-external-link-alt"
        label="Export"
        className="p-button-text p-button-secondary"
        onClick={() => console.log('Export')}
      />

      <h2>
        Lorem ipsum dolor sit amet
      </h2>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam velit magna, molestie eget lobortis et, sollicitudin non lacus. Donec accumsan arcu eu erat congue pretium pretium sed tellus.
      </p>

      <div className="sp--charts">
        <div className="sp--charts--tablist">
          <div className={activeTab === 'projects' ? 'active' : ''} onClick={() => setActiveTab('projects')}>
            <title>Progress by project</title>
            <div>
              <i className="las la-list-alt"></i>
              23/24
            </div>
            <div className="tab--progress-bar">
              <div style={{ width: (23 / 24) * 100 + '%' }}></div>
            </div>
          </div>
          <div className={activeTab === 'lead-institutions' ? 'active' : ''} onClick={() => setActiveTab('lead-institutions')}>
            <title>Progress by lead institution </title>
            <div>
              <i className="las la-list-alt"></i>
              14/35
            </div>
            <div className="tab--progress-bar">
              <div style={{ width: (14 / 35) * 100 + '%' }}></div>
            </div>
          </div>
        </div>
        <div className="sp--charts--tabcontent">
          {(activeTab === 'projects') && <StatisticsChart title='Projects' data={data} />}
          {(activeTab === 'lead-institutions') && <StatisticsChart title='Lead institutions' data={data} />}
        </div>
      </div>
    </div>
  );
};

export default SPImplementation;