// react imports
import React from "react";

// helper funcs
import { truncate } from "../../../apis/helperFunc";

// styles
import "./SelectedItems.scss"

/**
 * 
 * @param title: string
 * @param selectedItems: array of { id: number, name: string }
 * @param removeItem: callback: id => {}
 * @returns 
 */
const SelectedItems = (props) => {
  return (
    <div className="selected-items">
      <title>{props.title}</title>

      <div className="selected-items--list">
        {props.selectedItems.map(item => (
          <div key={item.id} className="selected-items--item" onClick={() => props.removeItem(item.id)}>
            <i className="las la-minus-circle"></i>
            <span>{truncate(item.name, 84)}</span>
          </div>
        ))}
      </div>

    </div>
  );
};

export default SelectedItems;