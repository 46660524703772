// react imports
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// prime react components
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';


// custom components
import NewProjectModal from "../common/modals/new-project-modal/NewProjectModal"
import FilterSelect from "../common/filter-select/FilterSelect";
import ActionMenu from "../common/action-menu/ActionMenu";


// consts, helper
import { ACTIVITIES, PROJECTS } from "../../apis/sampleData";
import { onlyUniqueValues } from "../../apis/helperFunc";
import { PAGINATOR_TEMPLATE } from '../../apis/constants';


// styles
import "./ProjectsPage.scss";


const ProjectsPage = () => {
  let history = useHistory();

  // consts
  const sortOptions = ['A-Z', 'Z-A'];
  const statusOptions = ['All', 'Completed', 'In progress', 'Not started'];
  const activityOptions = ['All', ...onlyUniqueValues(ACTIVITIES.map(a => a.name))];
  const projects = [{ id: -1, name: "Create new Project" }, ...PROJECTS];

  // filters state
  const [currentSort, setCurrentSort] = useState(sortOptions[0]);
  const [statusFilter, setStatusFilter] = useState(statusOptions[0]);
  const [activityFilter, setActivityFilter] = useState(activityOptions[0]);

  // new modal states
  const [isNewModalOpened, setIsNewModalOpened] = useState(false);

  // helper
  const getClickOnProject = projectId => () => {
    history.push(`/projects/${projectId}`);
  };

  // templates
  const projectTemplate = (project, layout) => {
    if (project.id === -1) {
      return (
        <div className="project-item create" onClick={() => setIsNewModalOpened(true)}>
          <i className="las la-plus-circle"></i>
          <h2>Create new Project</h2>
        </div>
      );
    }

    const progressBarColor = (function () {
      if (project.progress < 33) {
        return "#FE4D55";
      }

      if (project.progress < 66) {
        return "#FFAB2B";
      }

      return "#6DD230";
    }());

    return (
      <div className="project-item">
        <div className="project-item-top-bar">
          <div className="progress-bar-wrapper">
            <div style={{ width: project.progress + '%', backgroundColor: progressBarColor }}>

            </div>
          </div>

          <ActionMenu
            actions={[
              { name: 'Edit project settings' },
              { name: 'Edit permissions' }
            ]}
            actionSelected={(selectedActionName => console.log(selectedActionName))}
          />
        </div>

        <div className="project-item-content" onClick={getClickOnProject(project.id)}>
          <div className={`project-icon ${project.icon.theme}`}>
            <i className={project.icon.class} style={{ color: project.icon.color }}></i>
          </div >

          <h2>{project.name}</h2>

          <div className="project-implemented-infos">
            <div className="implemented-info">
              <div>
                <span>{project.recommendations.filter(r => r.implemented).length}</span>
                /
                <span>{project.recommendations.length}</span>
              </div>
              <p>
                Recommendations implemented
              </p>
            </div>

            <div className="implemented-info">
              <div>
                <span>{project.activities.filter(a => a.implemented).length}</span>
                /
                <span>{project.activities.length}</span>
              </div>
              <p>
                Activities completed
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper projects">
      <div className="projects-top-row">
        <div>
          <FilterSelect
            title="Status"
            iconClass="las la-info-circle"
            options={statusOptions}
            value={statusFilter}
            onSelectValue={setStatusFilter}
          />

          <FilterSelect
            title="Activity"
            iconClass="las la-clipboard-check"
            options={activityOptions}
            value={activityFilter}
            onSelectValue={setActivityFilter}
          />
        </div>

        <button className="blue-button" onClick={() => setIsNewModalOpened(true)}>Create New Project</button>
      </div>

      <NewProjectModal
        isOpen={isNewModalOpened}
        setModalOpened={setIsNewModalOpened}
      />

      <DataView
        value={projects}
        itemTemplate={projectTemplate}
        paginator
        paginatorTemplate={PAGINATOR_TEMPLATE}
        rows={6}
      ></DataView>
    </div>
  );
};

export default ProjectsPage;