// react imports
import React, { useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

// prime imports
import { Button } from "primereact/button";

// custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import ActivityDetailsContent from "./activity-details-content/ActivityDetailsContent";
import ActivitySidepanel from "./activity-sidepanel/ActivitySidepanel";
import Updates from "../../common/updates/Updates";

// sample
import { ACTIVITIES, IMPLEMENTATION_STATUSES, INSTITUTIONS, CURRENCIES, UPDATES } from "../../../apis/sampleData";

// styles
import "./ActivityPage.scss"

const ActivityPage = () => {
  // react helpers
  const params = useParams();
  const history = useHistory();

  // content
  const activity = useMemo(() => {
    const activityArray = ACTIVITIES.filter(a => a.id === parseInt(params.activityId));
    if (activityArray.length !== 1) {
      throw new Error(`There are no activity with id ${params.activityId}`);
      return;
    }

    return activityArray[0];
  }, [params.activityId]);

  // states
  const [selectedTab, setSelectedTab] = useState('Details');


  return (
    <div className="page-wrapper activity single-element white-bc">
      <div className="single-element-header">
        <div>
          <Button
            icon="las la-chevron-circle-left"
            className="p-button-text p-button-secondary"
            onClick={() => history.goBack()}
          />

          <div className="single-element-tabs">
            <Button
              label="Details"
              className={`p-button-text ${selectedTab === 'Details' ? '' : 'p-button-secondary'}`}
              onClick={() => setSelectedTab('Details')}
            />

            <Button
              label="Updates"
              className={`p-button-text ${selectedTab === 'Updates' ? '' : 'p-button-secondary'}`}
              onClick={() => setSelectedTab('Updates')}
            />
          </div>
        </div>

        <div>
          <Button
            icon="las la-external-link-alt"
            label="Export"
            className="p-button-text p-button-secondary"
            onClick={() => console.log("export")}
          />

          <ActionMenu
            actions={[
              { name: 'Activity permissions' },
              { name: 'Delete activity' }
            ]}
            actionSelected={(selectedActionName => console.log(selectedActionName))}
          />
        </div>
      </div>

      <div className="single-element-content">
        {selectedTab === "Details" && <ActivityDetailsContent activity={activity} />}

        {selectedTab === "Updates" && <Updates updateItems={UPDATES} />}

        <ActivitySidepanel activity={activity} />
      </div>
    </div>
  );
};

export default ActivityPage;