// react imports
import React, { useRef, useState } from "react";

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from "primereact/inputtext";

// import custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import ThemeDialog from "./theme-dialog/ThemeDialog";

// data imports
import { THEMES } from '../../../apis/sampleData';


const emptyTheme = { parentName: '', name: '' };


const ThemesPage = () => {
  // new theme dialog state
  const [newThemeDialogVisible, setNewThemeDialogVisible] = useState(false);

  // edit theme dialog states
  const [editThemeDialogVisible, setEditThemeDialogVisible] = useState(false);
  const [theme, setTheme] = useState(emptyTheme);

  const [searchText, setSearchText] = useState('');

  // events
  const getThemeActionClickHandler = (id) => {
    return (actionName) => {
      switch (actionName) {
        case 'Edit':
          setTheme(THEMES.find(t => t.id === id));
          setEditThemeDialogVisible(true);
          break;
        case 'Delete':
          confirmDialog({
            message: `Are you sure you want to delete ${THEMES.find(t => t.id === id).name}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { console.log('Deleted'); },
            reject: () => { }
          });
          break;
        default:
          break;
      }
    };
  };

  // templates
  const getActionMenuTemplate = rowData => {
    const actions = [
      { name: 'Edit', iconClass: "las la-edit" },
      { name: 'Delete', iconClass: "las la-trash" }
    ];

    return (
      <ActionMenu
        actions={actions}
        actionSelected={getThemeActionClickHandler(rowData.id)}
      />
    );
  };

  // refs
  const searchRef = useRef();

  const filteredThemes = THEMES.filter(theme => {
    return theme.name.toLowerCase().includes(searchText) || theme.parentName.toLowerCase().includes(searchText);
  });

  return (
    <div className="settings--tab-content themes">
      {/*<Editor style={{ height: '320px' }} placeholder="Additional information on implementation progress" />*/}

      <div className="settings--title-bar">
        <h1>Themes</h1>
        <div className="settings--buttons">

          <div className="p-inputgroup search-inputgroup">
            <label className="p-inputgroup-addon" htmlFor="search-theme">
              <i className="las la-search"></i>
            </label>
            <InputText
              placeholder="Search Theme"
              type="text"
              id="search-theme"
              ref={searchRef}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
          <Button
            icon="las la-plus-circle"
            label="New Theme"
            className="p-button-text"
            onClick={() => setNewThemeDialogVisible(true)}
          />
        </div>
      </div>

      <ThemeDialog
        theme={emptyTheme}
        dialogVisible={newThemeDialogVisible}
        saveButtonLabel="Create"
        dialogHeader="NEW THEME"
        onClose={() => setNewThemeDialogVisible(false)}
        onSave={(parentName, name) => { setNewThemeDialogVisible(false); console.log(parentName, name); }}
      />

      <ThemeDialog
        theme={theme}
        dialogVisible={editThemeDialogVisible}
        saveButtonLabel="Save"
        dialogHeader="EDIT THEME"
        onClose={() => setEditThemeDialogVisible(false)}
        onSave={(parentName, name) => { setEditThemeDialogVisible(false); console.log(parentName, name); }}
      />

      <DataTable
        value={filteredThemes}
        sortField="parentName"
        sortOrder={1}
        rowGroupMode="subheader"
        groupField="parentName"
        rowGroupHeaderTemplate={t => t.parentName}
        rowGroupFooterTemplate={t => false}
      >
        <Column className="id" field="id" header="ID"></Column>
        <Column className="name" field="name" header="Name"></Column>
        <Column className="actions" header="Actions" body={getActionMenuTemplate}></Column>
      </DataTable>
    </div >
  );
};

export default ThemesPage;