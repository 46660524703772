// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

// custom components
import AttachmentDialog from "../../../common/dialogs/attachment-dialog/AttachmentDialog";
import LinkRecommendationModal from "../../../common/modals/link-recommendation-modal/LinkRecommendationModal";
import AssignToProjectModal from "../../../common/modals/assign-to-project-modal/AssignToProjectModal";

// sample
import { PROJECTS, RECOMMENDATIONS } from "../../../../apis/sampleData";

// styles
import "./ActivitySidepanel.scss"

/**
 * 
 * @param activity
 * @returns 
 */
const ActivitySidepanel = (props) => {
  // states
  const [projectDialogVisible, setProjectDialogVisible] = useState(false);
  const [recommendationDialogVisible, setRecommendationDialogVisible] = useState(false);
  const [attachmentDialogVisible, setAttachmentDialogVisible] = useState(false);

  // consts
  const projects = props.activity.projectIds.map(projectId => PROJECTS.find(p => p.id === projectId));
  const recommendations = props.activity.recommendationIds.map(recommendationId => RECOMMENDATIONS.find(r => r.id === recommendationId));

  return (
    <div className="single-element-sidepanel">
      <Button
        icon="las la-plus"
        label="Assign to project"
        onClick={() => setProjectDialogVisible(true)}
      />

      <AssignToProjectModal
        dialogVisible={projectDialogVisible}
        onClose={() => setProjectDialogVisible(false)}
        onSave={() => setProjectDialogVisible(false)}
      />

      <div className="single-element-sidepanel-list projects">
        <title>In Projects</title>
        {projects.map(project => (
          <div className="sidepanel-list-item project-name-item" key={project.id}>
            <div className="item-content">
              {project.name}
            </div>
            <div className="remove-button" onClick={() => console.log(project.id)}>
              <i className="las la-times"></i>
            </div>
          </div>
        ))}
      </div>

      <div className="single-element-sidepanel-list recommendations">
        <title>Linked Recommendations</title>
        {recommendations.map(recommendation => (
          <div className="sidepanel-list-item" key={recommendation.id}>
            <div className="item-content">
              <i className="las la-list"></i>
              <span>{recommendation.name}</span>
            </div>
            <div className="remove-button" onClick={() => console.log(recommendation.id)}>
              <i className="las la-times"></i>
            </div>
          </div>
        ))}

        <Button
          icon="las la-link"
          label="Link recommendation"
          className="p-button-text link-recommendation"
          onClick={() => setRecommendationDialogVisible(true)}
        />

        <LinkRecommendationModal
          value=""
          options={RECOMMENDATIONS}
          optionValue="id"
          optionLabel="name"
          dialogVisible={recommendationDialogVisible}
          onClose={() => setRecommendationDialogVisible(false)}
          onSave={() => setRecommendationDialogVisible(false)}
        />

        {/* <LinkRecommendationModal
          dialogVisible={recommendationDialogVisible}
          onClose={() => setRecommendationDialogVisible(false)}
        /> */}

      </div>

      <div className="single-element-sidepanel-list attachments">
        <title>Attachments</title>
        {props.activity.attachments.map(attachment => (
          <div className="sidepanel-list-item" key={attachment.name}>
            <a className="item-content" href={attachment.url} target="_blank" rel="noreferrer">
              <i className="lar la-file"></i>
              <span>{attachment.name}</span>
            </a>
            <div className="remove-button" onClick={() => console.log(attachment.name)}>
              <i className="las la-times"></i>
            </div>
          </div>
        ))}

        <Button
          icon="las la-plus-circle"
          label="Add attachments"
          className="p-button-text"
          onClick={() => setAttachmentDialogVisible(true)}
        />

        <AttachmentDialog
          value=""
          options={RECOMMENDATIONS}
          optionValue="id"
          optionLabel="name"
          dialogVisible={attachmentDialogVisible}
          dialogHeader="Link Recommendation"
          onClose={() => setAttachmentDialogVisible(false)}
          onSave={() => setAttachmentDialogVisible(false)}
        />
      </div>
    </div>
  );
};

export default ActivitySidepanel;