// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';

// custom components
import TextInputDialog from "../../../common/dialogs/text-input-dialog/TextInputDialog";
import TextAreaDialog from "../../../common/dialogs/textarea-dialog/TextAreaDialog";

// sample
import { PROJECTS, ACTION } from "../../../../apis/sampleData";
import { getImplementationStatusTemplate } from "../../../../apis/temlates";

// styles
import "./ProjectDetailsContent.scss"

/**
 * 
 * @param project: PROJECT
 * @returns 
 */
const ProjectDetailsContent = (props) => {
  // states
  const [editNameDialogVisible, setEditNameDialogVisible] = useState(false);
  const [editDescriptionDialogVisible, setEditDescriptionDialogVisible] = useState(false);

  const progressBarColor = (function () {
    if (props.project.progress < 33) {
      return "#FE4D55";
    }

    if (props.project.progress < 66) {
      return "#FFAB2B";
    }

    return "#6DD230";
  }());

  return (
    <div className="single-element-tab-content details-content">
      <div className="name inline-edit-block">
        <div className="project-icon-name-wrapper">
          <div className="project-item-icon-wrapper" style={{ backgroundColor: props.project.icon.bgColor }}>
            <i className={props.project.icon.class} style={{ color: props.project.icon.color }}></i>
          </div >

          <span>{props.project.name}</span>

          <div className="progress-bar-wrapper">
            <div style={{ width: props.project.progress + '%', backgroundColor: progressBarColor }}>

            </div>
          </div>
        </div>

        <Button
          icon="las la-pencil-alt"
          className="p-button-text p-button-secondary"
          onClick={() => setEditNameDialogVisible(true)}
        />

        <TextInputDialog
          value={props.project.name}
          dialogVisible={editNameDialogVisible}
          dialogHeader="Edit Title"
          onClose={() => setEditNameDialogVisible(false)}
          onSave={() => setEditNameDialogVisible(false)}
        />
      </div>

      <p className="description inline-edit-block">
        <span>{props.project.description}</span>
        <Button
          icon="las la-pencil-alt"
          className="p-button-text p-button-secondary"
          onClick={() => setEditDescriptionDialogVisible(true)}
        />

        <TextAreaDialog
          value={props.project.description}
          dialogVisible={editDescriptionDialogVisible}
          dialogHeader="Edit Description"
          onClose={() => setEditDescriptionDialogVisible(false)}
          onSave={() => setEditDescriptionDialogVisible(false)}
        />
      </p>

      <div className="project-implemented-infos">
        <div className="implemented-info">
          <div>
            <span>{props.project.recommendations.filter(r => r.implemented).length}</span>
            /
            <span>{props.project.recommendations.length}</span>
          </div>
          <p>
            Recommendations implemented
          </p>
        </div>

        <div className="implemented-info">
          <div>
            <span>{props.project.activities.filter(a => a.implemented).length}</span>
            /
            <span>{props.project.activities.length}</span>
          </div>
          <p>
            Activities completed
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsContent;