// react imports
import React, { useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// prime imports
import { Button } from "primereact/button";

// custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import Updates from "../../common/updates/Updates";
import ProjectDetailsContent from "./project-details-content/ProjectDetailsContent";
import ProjectSidepanel from "./project-sidepanel/ProjectSidepanel";
import LinkActivityModal from "../../common/modals/link-activity-modal/LinkActivityModal";
import RecommendationList from "../../recommendations/recommendation-list/RecommendationList";
import RecommendationsFilterPanel from "../../recommendations/recommendations-filter-panel/RecommendationsFilterPanel";
import ActivitiesFilterPanel from "../../activities/activities-filter-panel/ActivitiesFilterPanel";
import ActivitiesList from "../../activities/activities-list/ActivitiesList";
import CreateActivityModal from "../../common/modals/create-activity-modal/CreateActivityModal";
import LinkRecommendationModal from "../../common/modals/link-recommendation-modal/LinkRecommendationModal";

// sample
import { PROJECTS, UPDATES, ACTIVITIES, RECOMMENDATIONS, EMPTY_PROJECT } from "../../../apis/sampleData";

// imgs
import addListsImg from "../../../imgs/add-lists.png";

// styles
import "./ProjectPage.scss"

const ProjectPage = () => {
  let history = useHistory();

  // react helpers
  const params = useParams();

  // content
  const project = (params.projectId !== 'empty') ?
    PROJECTS.find(p => p.id === parseInt(params.projectId)) :
    EMPTY_PROJECT;

  // states
  const [selectedTab, setSelectedTab] = useState('Details');
  const [itemsTab, setItemsTab] = useState('Recommendations');
  const [displayListsForEmpty, setDisplayListsForEmpty] = useState(false);

  // modal states
  const [linkActivityDialogVisible, setLinkActivityDialogVisible] = useState(false);
  const [createActivityModalVisible, setCreateActivityModalVisible] = useState(false);
  const [recommendationDialogVisible, setRecommendationDialogVisible] = useState(false);

  // helper funcs
  const getAddListsPanel = () => (
    <div className="projects-add-lists">
      <img src={addListsImg} alt="Add List" />
      <h2>Add recommendations or activities to your project</h2>
      <p>Lorem ipsum dolor sit amet eripteum sit dolor</p>

      <Button
        label="Add recommendations or activities"
        onClick={() => setDisplayListsForEmpty(true)}
      />
    </div>
  );
  const getLists = () => (
    <div className="project-items">
      <div className="project-items-top-row">
        <div className="project-items-tabs">
          <Button
            label="Recommendations"
            className={`p-button-text ${itemsTab !== "Recommendations" && 'p-button-secondary'}`}
            badge="32"
            onClick={(e) => setItemsTab('Recommendations')}
          />

          <Button
            label="Activities"
            className={`p-button-text ${itemsTab !== "Activities" && 'p-button-secondary'}`}
            badge="12"
            onClick={(e) => setItemsTab('Activities')}
          />
        </div>

        <div className="project-items-specific-links">
          {itemsTab === "Recommendations" && (
            <>
              <Button
                icon="las la-list-ul"
                label="Assign recommendations"
                onClick={() => setRecommendationDialogVisible(true)}
              />

              <LinkRecommendationModal
                value=""
                options={RECOMMENDATIONS}
                optionValue="id"
                optionLabel="name"
                dialogVisible={recommendationDialogVisible}
                onClose={() => setRecommendationDialogVisible(false)}
                onSave={() => setRecommendationDialogVisible(false)}
              />
            </>
          )}

          {itemsTab === "Activities" && (
            <>
              <Button
                icon="las la-clipboard-check"
                label="Link activitiy"
                className="p-button-text link-activity"
                onClick={() => setLinkActivityDialogVisible(true)}
              />

              <LinkActivityModal
                dialogVisible={linkActivityDialogVisible}
                onClose={() => setLinkActivityDialogVisible(false)}
                onSave={() => setLinkActivityDialogVisible(false)}
              />

              <Button
                icon="las la-notes-medical"
                label="Create Activity"
                onClick={() => setCreateActivityModalVisible(true)}
              />

              <CreateActivityModal
                onSave={() => setCreateActivityModalVisible(false)}
                onClose={() => setCreateActivityModalVisible(false)}
                modalVisible={createActivityModalVisible}
              />
            </>
          )}
        </div>
      </div>

      {itemsTab === "Recommendations" && (
        <>
          <RecommendationsFilterPanel extended={false} />

          <RecommendationList
            recommendationListHeight={1000}
            recommendationClickable={false}
          />
        </>
      )}

      {itemsTab === "Activities" && (
        <>
          <ActivitiesFilterPanel />

          <ActivitiesList
            activities={ACTIVITIES}
            activitiesListHeight={1000}
            sortBy="IMPLEMENTATION STATUS"
          />
        </>
      )}
    </div>
  );

  return (
    <div className="page-wrapper project single-element">
      <div className="white-bc">
        <div className="single-element-header">
          <div>
            <Button
              icon="las la-chevron-circle-left"
              className="p-button-text p-button-secondary"
              onClick={() => history.goBack()}
            />

            <div className="single-element-tabs">
              <Button
                label="Details"
                className={`p-button-text ${selectedTab === 'Details' ? '' : 'p-button-secondary'}`}
                onClick={() => setSelectedTab('Details')}
              />

              <Button
                label="Updates"
                className={`p-button-text ${selectedTab === 'Updates' ? '' : 'p-button-secondary'}`}
                onClick={() => setSelectedTab('Updates')}
              />
            </div>
          </div>

          <div>
            <Button
              icon="las la-external-link-alt"
              label="Export"
              className="p-button-text p-button-secondary"
              onClick={() => console.log("export")}
            />

            <ActionMenu
              actions={[
                { name: 'Project permissions' },
                { name: 'Delete Project' }
              ]}
              actionSelected={(selectedActionName => console.log(selectedActionName))}
            />
          </div>
        </div>

        <div className="single-element-content">
          {selectedTab === "Details" && <ProjectDetailsContent project={project} />}

          {selectedTab === "Updates" && <Updates updateItems={UPDATES} />}

          <ProjectSidepanel project={project} />
        </div>
      </div>
      {selectedTab === 'Details' && (!displayListsForEmpty && params.projectId === 'empty') && getAddListsPanel()}

      {selectedTab === 'Details' && (displayListsForEmpty || params.projectId !== 'empty') && getLists()}
    </div>
  );
};

export default ProjectPage;