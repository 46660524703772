// react imports
import React, { useState } from "react";

// prime react imports
import { Button } from "primereact/button";

// import custom components
import RecommendationsFilterPanel from "./recommendations-filter-panel/RecommendationsFilterPanel";
import RecommendationList from "./recommendation-list/RecommendationList";
import ImportRecommendationsModal from "../common/modals/import-recommendations-modal/ImportRecommendationsModal";

// styles
import "./RecommendationsPage.scss";

const RecommendationsPage = () => {
  // modal states
  const [importRecommendatiosModalVisible, setImportRecommendatiosModalVisible] = useState(false);

  return (
    <div className="recommendations-page page-wrapper">
      <Button
        icon="lar la-folder-open"
        label="Import Recommendations"
        className="import-recommendations p-button-text p-button-secondary"
        onClick={(e) => setImportRecommendatiosModalVisible(true)}
      />

      <ImportRecommendationsModal
        onClose={() => setImportRecommendatiosModalVisible(false)}
        modalVisible={importRecommendatiosModalVisible}
      />

      <RecommendationsFilterPanel extended={true} />

      <RecommendationList
        recommendationClickable={true}
        isRecommendationsAddable={false}
        isAssignableToProject={true}
        isLinkableToActivity={true}
        isRecommendationsHighlightable={true}
        isMultipleAddingAvailable={true}
        multipleAddingLinkText="Add #NUMBERS Recommendations"
      />
    </div>
  );
};

export default RecommendationsPage;