// react
import { useState } from "react";

// styles
import "./HomeCalendar.scss";

// consts
import { MONTHS } from "../../../apis/constants";

/**
 * Props properties:
 * @param marked: array of {year: number, month: string}
 * @param selected: {year: number, month: string}
 * @param onSelectMonthDate: callback func: ({year: number, month: string}) => {}
 */

const HomeCalendar = props => {
  // states
  const [year, setYear] = useState(new Date().getFullYear());

  // consts
  const markedMonths = [];
  const currentYear = new Date().getFullYear();
  const currentMonth = MONTHS[new Date().getMonth()];

  // events
  const selectMonthDate = month => {
    props.onSelectMonthDate({ year, month });
  };

  // filled marked months, based on props
  props.marked.forEach(mark => {
    if (mark.year === year) {
      markedMonths.push(mark.month);
    }
  });

  return (
    <div className="home-calendar">
      <header>
        <div className="year">{year}</div>
        <div className="arrows">
          <i onClick={() => setYear(year - 1)} className="las la-angle-left"></i>
          <i onClick={() => setYear(year + 1)} className="las la-angle-right"></i>
        </div>
      </header>
      <main>
        {MONTHS.map(month => {
          let classes = [];

          if (year === currentYear && month === currentMonth) {
            classes.push('current');
          }

          if (markedMonths.includes(month)) {
            classes.push('marked');
          }

          if (year === props.selected.year && month === props.selected.month) {
            classes.push('selected');
          }

          return <div key={month} className={classes.join(' ')} onClick={() => selectMonthDate(month)}>
            <span></span>
            {month}
          </div>;
        })}
      </main>
    </div>
  );
};

export default HomeCalendar;