// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

// const imports
import { MECHANISM_TYPES } from "../../../../apis/sampleData";

/**
 * Properties:
 * @param mechanism: {id: number, name: string, abbreviation: string, mechanismType: string}
 * @param dialogVisible: bool
 * @param saveButtonLabel: string
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (id, name, abbreviation, mechanismType) => {}
 */
const MechanismDialog = (props) => {
  // states
  const [name, setName] = useState(props.mechanism.name);
  const [abbreviation, setAbbreviation] = useState(props.mechanism.abbreviation);
  const [mechanismType, setMechanismType] = useState(props.mechanism.mechanismType);

  useEffect(() => {
    setName(props.mechanism.name);
    setAbbreviation(props.mechanism.abbreviation);
    setMechanismType(props.mechanism.mechanismType);
  }, [props.mechanism.name, props.mechanism.abbreviation, props.mechanism.mechanismType])

  // events
  const saveClickHandler = () => {
    props.onSave(props.mechanism.id, name, abbreviation, mechanismType);
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          < div className="p-field">
            <label htmlFor="mechanism-name">Mechanism Name</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id="mechanism-name" type="text" />
          </div>
          <div className="p-field">
            <label htmlFor="mechanism-abbreviation">Abbreviation</label>
            <InputText value={abbreviation} onChange={(e) => setAbbreviation(e.target.value)} id="mechanism-abbreviation" type="text" />
          </div>
          <div className="p-field">
            <label htmlFor="mechanism-type">Mechanism Type</label>
            <Dropdown
              id="mechanism-type"
              options={MECHANISM_TYPES.map(mt => mt.name)} value={mechanismType} onChange={(e) => setMechanismType(e.value)} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MechanismDialog;