// react
import React, { useRef, useState } from "react";

// primereact components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toolbar } from "primereact/toolbar";
import { Badge } from "primereact/badge";
import { Avatar } from 'primereact/avatar';


// style
import "./Header.scss";
import { NavLink } from "react-router-dom";

/**
 * 
 * @param title: string
 * @returns 
 */
const Header = props => {
  // states
  const [searchText, setSearchText] = useState();

  // consts
  let timeoutId = 0;

  // refs
  const searchPanelRef = useRef();

  // events
  const searchTextChanged = e => {
    setSearchText(e.target.value);

    if (typeof e.target.value === "string" && timeoutId === 0) {
      timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        timeoutId = 0;

        if (e.target.value.length > 2) {
          searchPanelRef.current.show(e);
        } else {
          searchPanelRef.current.hide();
        }
      }, 500);
    }
  };

  // helper jsx
  const topBarleftContents = (
    <>
      {props.title}
    </>
  );

  const topBarRightContents = (
    <>
      <div className="p-inputgroup search-inputgroup">
        <label className="p-inputgroup-addon" htmlFor="toolbar-search-input">
          <i className="las la-search"></i>
        </label>

        <InputText
          placeholder="Search for recommendations, activities"
          type="text"
          id="toolbar-search-input"
          onChange={searchTextChanged}
          value={searchText}
        />
        <Button type="button" onClick={e => searchPanelRef.current.toggle(e)}>
          <i className="las la-arrow-right"></i>
        </Button>

      </div>

      <OverlayPanel className="search-results" ref={searchPanelRef} showCloseIcon dismissable>
        <div className="result-block">
          <header>
            <div className="title">
              <i className="las la-th-list"></i>
              Projects
            </div>
            <span>4</span>
          </header>
          <main>
            <div className="result">
              <NavLink to="/projects" onClick={() => searchPanelRef.current.hide()}>
                Equality & non discrimination
              </NavLink>
            </div>

            <div className="result">
              <NavLink to="/projects" onClick={() => searchPanelRef.current.hide()}>
                Members of minorities
              </NavLink>
            </div>
          </main>
        </div>
        <div className="result-block">
          <header>
            <div className="title">
              <i className="las la-tachometer-alt"></i>
              Recommendations
            </div>
            <span>32</span>
          </header>
          <main>
            <div className="result">
              <NavLink to="/recommendations" onClick={() => searchPanelRef.current.hide()}>
                03/2016
              </NavLink>
              <NavLink to="/recommendations" onClick={() => searchPanelRef.current.hide()}>
                CEDAW
              </NavLink>
            </div>

            <div className="result">
              <NavLink to="/recommendations" onClick={() => searchPanelRef.current.hide()}>
                2/2014
              </NavLink>
              <NavLink to="/recommendations" onClick={() => searchPanelRef.current.hide()}>
                CAT
              </NavLink>
            </div>
          </main>
        </div>
        <div className="result-block">
          <header>
            <div className="title">
              <i className="las la-list-alt"></i>
              Activities / Actions
            </div>
            <span>12</span>
          </header>
          <main>
            <div className="result">
              <NavLink to="/activities" onClick={() => searchPanelRef.current.hide()}>
                Lorem ipsum dolor sit amet, has cu aeque eleifend inciderint
              </NavLink>
            </div>

            <div className="result">
              <NavLink to="/activities" onClick={() => searchPanelRef.current.hide()}>
                Dico nulla vulputate his ei, ex dicta dicunt quaeque cum, eum iisque patrioque no
              </NavLink>
            </div>
          </main>
        </div>
      </OverlayPanel>

      <i className="las la-bell p-text-secondary p-overlay-badge">
        <Badge severity="danger"> </Badge>
      </i>

      <NavLink to="/profile">
        <Avatar label="BA" shape="circle" />
      </NavLink>
    </>
  );

  return (
    <>
      <Toolbar
        left={topBarleftContents}
        right={topBarRightContents}
      />
    </>
  );
};

export default Header;