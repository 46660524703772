// react imports
import React from "react";

// sample
import { INSTITUTIONS } from "../../apis/sampleData";

// prime react imports
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from 'primereact/fileupload';
import { Button } from "primereact/button";


// styles
import "./Profile.scss"


const Profile = () => {


  return (
    <div className="profile page-wrapper white-bc">
      <div className="p-fluid p-formgrid p-grid profile-form">
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="first-name">First name</label>
          <InputText id="first-name" type="text" />
        </div>
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="last-name">Last name</label>
          <InputText id="last-name" type="text" />
        </div>
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="email">Email</label>
          <InputText id="email" type="email" placeholder="Enter your mail" />
        </div>
        <div className="p-field p-col-12 p-md-6">
          <label htmlFor="institution">Institution</label>
          <Dropdown
            id="institution"
            options={INSTITUTIONS}
            optionLabel="name"
            optionValue="id"
            onChange={(e) => console.log(e.value)}
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="email">Profile picture</label>
          <FileUpload
            name="picture[]"
            url="./upload"
            multiple
            accept="image/*"
            mode="basic"
            chooseLabel=""
            uploadLabel=""
            cancelLabel=""
          />
        </div>
        <div className="p-field p-col-12">
          <Button
            icon="las la-exchange-alt"
            label="CHANGE PASSWORD"
            className="p-button-text change-password-button"
          />
        </div>
        <div className="p-field p-col-12">
          <Button
            label="Saved"
            className="save-button"
            onClick={() => console.log('Profile saved')}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;