// react imports
import React, { useState } from "react";

// prime react components
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

// styles
import "./RecommendationsFilterPanel.scss";

// const
import { THEMES, SDG, MECHANISM_TYPES, SDGs, RECOMMENDING_BODIES } from "../../../apis/sampleData";
const GROUPS = ['GROUP 1', 'GROUP 2', 'GROUP 3', 'GROUP 4', 'GROUP 5', 'GROUP 6'];


/**
 * Properties:
 * @param extended: boolean (default: false)
 * @param onClose: () => {}
 * @returns 
 */
const RecommendationsFilterPanel = (props) => {
  // states
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedSDGs, setSelectedSDGs] = useState('');
  const [selectedGroup, setSelectedGroups] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedRecommendingBody, setSelectedRecommendingBody] = useState('');

  // events
  const clickOnReset = () => {
    setSelectedRecommendingBody([]);
    setSelectedThemes([]);
    setSelectedSDGs([]);
    setSelectedGroups([]);
    setSearchKeyword('');
  };

  // consts
  const sdgs = [];
  SDGs.forEach(sdg => {
    sdgs.push(sdg.title);

    sdg.subSdgs.forEach(subSdg => {
      sdgs.push(subSdg);
    });
  });

  return (
    <div className={`filter-panel recommendations-filter-panel ${props.extended ? "extended" : ""}`}>
      <div className="p-fluid p-formgrid p-grid">
        {props.extended && (
          <div className="p-field p-col">
            <label htmlFor="recommending-bodies">Recommending body</label>
            <MultiSelect
              id="recommending-bodies"
              display="chip"
              value={selectedRecommendingBody}
              options={RECOMMENDING_BODIES}
              onChange={(e) => setSelectedRecommendingBody(e.value)}
              showSelectAll={false}
              maxSelectedLabels="1"
              placeholder="Select"
            />
          </div>
        )}

        <div className="p-field p-col">
          <label htmlFor="themes">Themes</label>
          <MultiSelect
            id="themes"
            display="chip"
            value={selectedThemes}
            options={THEMES}
            optionLabel="name"
            optionValue="id"
            onChange={(e) => setSelectedThemes(e.value)}
            showSelectAll={false}
            maxSelectedLabels="1"
            placeholder="Select"
          />
        </div>
        <div className="p-field p-col">
          <label htmlFor="sdgs">SDGs</label>
          <MultiSelect
            id="sdgs"
            display="chip"
            value={selectedSDGs}
            options={sdgs}
            onChange={(e) => setSelectedSDGs(e.value)}
            placeholder="Select"
          />
        </div>

        {!props.extended && (
          <>
            <div className="p-field p-col">
              <label htmlFor="groups">Groups</label>
              <MultiSelect
                id="groups"
                display="chip"
                value={selectedGroup}
                options={GROUPS}
                onChange={(e) => setSelectedGroups(e.value)}
                maxSelectedLabels={2}
                placeholder="Select"
              />
            </div>

            <div className="p-field p-col keyword-col">
              <InputText placeholder="Type keyword" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </div>
          </>
        )}
      </div>
      <div className="p-fluid p-formgrid p-grid">
        {props.extended && (
          <>
            <div className="p-field p-col">
              <label htmlFor="groups">Groups</label>
              <MultiSelect
                id="groups"
                display="chip"
                value={selectedGroup}
                options={GROUPS}
                onChange={(e) => setSelectedGroups(e.value)}
                maxSelectedLabels={0}
                placeholder="Select"
              />
            </div>

            <div className="p-field p-col keyword-col">
              <InputText placeholder="Type keyword" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </div>
          </>
        )}

        <div className="p-field p-col button-col">
          {props.extended && (
            <Button label="ADVANCED FILTERS" onClick={props.onClose} className="p-button-secondary" />
          )}
          <a className="reset-link" onClick={clickOnReset}>
            Reset
          </a>
        </div>
      </div>
    </div>
  );
};

export default RecommendationsFilterPanel;