// react imports
import React, { useRef, useState } from "react";

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';

// import custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import MechanismTypeDialog from './mechanism-type-dialog/MechanismTypeDialog';
import MechanismDialog from "./mechanism-dialog/MechanismDialog";

// data imports
import { MECHANISMS, MECHANISM_TYPES } from '../../../apis/sampleData';
import { PAGINATOR_TEMPLATE } from '../../../apis/constants';

const emptyMechanism = { id: -1, name: '', abbreviation: '', mechanismType: '' };


const HRMechanismsPage = () => {
  // institution type state
  const [mechanismTypeDialogVisible, setMechanismTypeDialogVisible] = useState(false);

  // new institution dialog state
  const [newMechanismDialogVisible, setNewMechanismDialogVisible] = useState(false);

  // edit institution dialog states
  const [editMechanismDialogVisible, setEditMechanismDialogVisible] = useState(false);
  const [editingMechanism, setMechanismMechanism] = useState(emptyMechanism);

  // refs
  const typesOverlayRef = useRef();

  // events
  const getMechanismActionClickHandler = (mechanismId) => {
    return (actionName) => {
      switch (actionName) {
        case 'Edit':
          const mechanismToEdit = MECHANISMS.find(m => m.id === mechanismId);
          setMechanismMechanism({
            id: mechanismToEdit.id,
            name: mechanismToEdit.name,
            abbreviation: mechanismToEdit.abbreviation,
            mechanismType: mechanismToEdit.mechanismType
          });
          setEditMechanismDialogVisible(true);
          break;
        case 'Delete':
          const mechanismToDelete = MECHANISMS.find(m => m.id === mechanismId);
          confirmDialog({
            message: `Are you sure you want to delete ${mechanismToDelete.name}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { console.log('Deleted'); },
            reject: () => { }
          });
          break;
        default:
          break;
      }
    };
  };

  // templates
  const getActionMenuTemplate = rowData => {
    const actions = [
      { name: 'Edit', iconClass: "las la-edit" },
      { name: 'Delete', iconClass: "las la-trash" }
    ];

    return (
      <ActionMenu
        actions={actions}
        actionSelected={getMechanismActionClickHandler(rowData.id)}
      />
    );
  };


  return (
    <div className="settings--tab-content">
      {/*<Editor style={{ height: '320px' }} placeholder="Additional information on implementation progress" />*/}

      <div className="settings--title-bar">
        <h1>Mechanisms</h1>
        <div className="settings--buttons">
          <Button
            icon="las la-th-list"
            label="View Mechanism Types"
            className="p-button-text p-button-secondary"
            onClick={(e) => typesOverlayRef.current.toggle(e)}
          />

          <OverlayPanel ref={typesOverlayRef} showCloseIcon dismissable>
            <DataTable
              value={MECHANISM_TYPES}
              paginator
              paginatorTemplate={PAGINATOR_TEMPLATE}
              rows={5}
              sortField="name"
              sortOrder={1}
            >
              <Column className="name" field="name" header="Name" sortable></Column>
            </DataTable>
          </OverlayPanel>

          <Button
            icon="las la-plus-circle"
            label="New Mechanism Type"
            className="p-button-text"
            onClick={() => setMechanismTypeDialogVisible(true)}
          />

          <Button
            icon="las la-plus-circle"
            label="New Mechanism"
            className="p-button-text"
            onClick={() => setNewMechanismDialogVisible(true)}
          />
        </div>
      </div>

      <MechanismTypeDialog
        dialogVisible={mechanismTypeDialogVisible}
        onClose={() => setMechanismTypeDialogVisible(false)}
        onCreate={newMechanismType => { setMechanismTypeDialogVisible(false); console.log(newMechanismType); }}
      />

      <MechanismDialog
        mechanism={emptyMechanism}
        dialogVisible={newMechanismDialogVisible}
        saveButtonLabel="Create"
        dialogHeader="NEW MECHANISM"
        onClose={() => setNewMechanismDialogVisible(false)}
        onSave={(id, name, abbreviation, mechanismType) => { setNewMechanismDialogVisible(false); console.log(id, name, abbreviation, mechanismType); }}
      />

      <MechanismDialog
        mechanism={editingMechanism}
        dialogVisible={editMechanismDialogVisible}
        saveButtonLabel="Save"
        dialogHeader="EDIT MECHANISM"
        onClose={() => setEditMechanismDialogVisible(false)}
        onSave={(id, name, abbreviation, mechanismType) => { setEditMechanismDialogVisible(false); console.log(id, name, abbreviation, mechanismType); }}
      />

      <DataTable
        value={MECHANISMS}
        paginator
        paginatorTemplate={PAGINATOR_TEMPLATE}
        rows={10}
        sortField="id"
        sortOrder={1}
      >
        <Column className="id" field="id" header="ID" sortable></Column>
        <Column className="name" field="name" header="Name" sortable></Column>
        <Column className="abbreviation" field="abbreviation" header="Abbreviation" sortable></Column>
        <Column field="mechanismType" header="Type" sortable></Column>
        <Column className="actions" header="Actions" body={getActionMenuTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default HRMechanismsPage;