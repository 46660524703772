// react imports
import React, { useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

// prime imports
import { Button } from "primereact/button";

// custom components
// import ActionMenu from "../../common/action-menu/ActionMenu";
import ReportDetailsContent from "./report-details-content/ReportDetailsContent";
import ReportSidepanel from "./report-sidepanel/ReportSidepanel";
import Updates from "../../common/updates/Updates";

// sample
import { MECHANISMS, INSTITUTIONS, UPDATES } from "../../../apis/sampleData";

// styles
import "./ReportPage.scss"

const ReportPage = () => {
  // react helpers
  const params = useParams();
  const history = useHistory();

  // content
  const report = useMemo(() => {
    const mechanism = MECHANISMS.find(m => m.id === params.mechanismId);
    if (typeof mechanism === 'undefined') {
      throw new Error(`There are no mechanism with id ${params.mechanismId}`);
      return;
    }

    const report = mechanism.requests.find(r => r.id === parseInt(params.reportId));
    if (typeof report === 'undefined') {
      throw new Error(`There are no report with id ${params.reportId}`);
      return;
    }

    return report;
  }, [params.mechanismId, params.reportId]);

  // states
  const [selectedTab, setSelectedTab] = useState('Details');
  const [wordCount, setWordCount] = useState(0);


  return (
    <div className="page-wrapper report single-element white-bc">
      <div className="single-element-header">
        <div>
          <Button
            icon="las la-chevron-circle-left"
            className="p-button-text p-button-secondary"
            onClick={() => history.goBack()}
          />

          <div className="single-element-tabs">
            <Button
              label="Details"
              className={`p-button-text ${selectedTab === 'Details' ? '' : 'p-button-secondary'}`}
              onClick={() => setSelectedTab('Details')}
            />

            <Button
              label="Updates"
              className={`p-button-text ${selectedTab === 'Updates' ? '' : 'p-button-secondary'}`}
              onClick={() => setSelectedTab('Updates')}
            />
          </div>
        </div>
      </div>

      <div className="single-element-content">
        {selectedTab === "Details" && <ReportDetailsContent wordCountChanged={(newWordCount) => setWordCount(newWordCount)} /> }
        {selectedTab === "Updates" && <Updates updateItems={UPDATES} /> }

        <ReportSidepanel report={report} wordCount={wordCount} />
      </div>
    </div>
  );
};

export default ReportPage;