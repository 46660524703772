import React from 'react';

import "./CollapseTrigger.scss";

/**
  * Props properties:
 * @param opened: boolean
 * @param stateChanged: callback: (triggerPropertyObject) => {}
 * @param triggerPropertyObject: any
 * @param arrowOnRight: boolean
 * @param noArrow: boolean
 * @param className: string
 */
const CollapseTrigger = (props) => {
  const classes = ['collapse-trigger'];

  // events
  const clickOnCollapseTrigger = (e) => {
    if (e.target.tagName.toUpperCase() === 'A') {
      return;
    }

    if (e.target.classList.contains('la-external-link-alt')) {
      return;
    }

    props.stateChanged(props.triggerPropertyObject);
  };

  if (props.className) classes.push(props.className);
  if (props.opened) classes.push('opened');
  if (props.arrowOnRight) classes.push('arrow-on-right');

  return (
    <div className={classes.join(' ')} onClick={clickOnCollapseTrigger}>
      {(!props.noArrow & !props.arrowOnRight) ? (<i className="las la-angle-up collapse-trigger-arrow"></i>) : ''}

      <div className="collapse-trigger-content">
        {props.children}
      </div>

      {(!props.noArrow & props.arrowOnRight) ? (<i className="las la-angle-up collapse-trigger-arrow"></i>) : ''}
    </div>
  );
}

export default CollapseTrigger;