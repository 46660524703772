// react, react router imports
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";

// PRIMEREACT styles
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';

// APP resources
import './App.scss';
import './fonts/line-awesome/css/line-awesome.min.css'

// PRIMEREACT components
import { Sidebar } from 'primereact/sidebar';

// pages, layout
import Header from "./components/common/header/Header";
import Home from "./components/home/Home";
import ProjectsPage from "./components/projects/ProjectsPage";
import Recommendations from "./components/recommendations/RecommendationsPage";
import ActivitiesPage from "./components/activities/ActivitiesPage";
import Reporting from "./components/reporting/Reporting";
import Statistics from "./components/statistics/Statistics";
import Settings from "./components/settings/Settings";
import Profile from "./components/profile/Profile";
import ActivityPage from "./components/activities/activity-page/ActivityPage";
import RecommendationPage from "./components/recommendations/recommendation-page/RecommendationPage";
import ProjectPage from "./components/projects/project-page/ProjectPage";
import ReportPage from "./components/reporting/report-page/ReportPage";

// images
import logo from "./imgs/nrtd-logo.jpg";


function App() {
  return (
    <>
      <Sidebar
        visible={true}
        showCloseIcon={false}
        modal={false}
      >
        <div className="nrtd-logo-wrapper">
          <img src={logo} alt="NRTD Logo" />
        </div>

        <ul>
          <li>
            <NavLink to="/home">
              <div><i className="las la-tachometer-alt"></i></div>
              Dashboard
            </NavLink>
          </li>

          <li>
            <NavLink to="/projects">
              <div><i className="las la-suitcase"></i></div>
              Projects
            </NavLink>
          </li>

          <li>
            <NavLink to="/recommendations">
              <div><i className="las la-th-list"></i></div>
              Recommendations
            </NavLink>
          </li>

          <li>
            <NavLink to="/activities">
              <div><i className="las la-list-alt"></i></div>
              Activities
            </NavLink>
          </li>

          <li>
            <NavLink to="/reporting">
              <div><i className="las la-comment"></i></div>
              Reporting
            </NavLink>
          </li>

          <li>
            <NavLink className="statistics" to="/statistics">
              <div><i className="las la-chart-pie"></i></div>
              Statistics
            </NavLink>
          </li>

          <li>
            <NavLink className="settings" to="/settings">
              <div><i className="las la-cog"></i></div>
              Settings
            </NavLink>
          </li>
        </ul>

      </Sidebar>
      <div className={"app with-sidebar"}>
        <div className={"sidebar-push-wrapper visible"}>
        </div>

        <div className="app-content">
          <Switch>
            <Route path='/' exact>
              <Redirect to='/home' />
            </Route>
            <Route path='/home'>
              <Header title="Dashboard" />
              <Home />
            </Route>
            <Route path='/projects' exact>
              <Header title="Projects" />
              <ProjectsPage />
            </Route>
            <Route path='/projects/:projectId'>
              <Header title="Projects" />
              <ProjectPage />
            </Route>
            <Route path='/recommendations' exact>
              <Header title="Recommendations" />
              <Recommendations />
            </Route>
            <Route path='/recommendations/:mechanismId/:dateString/:recommendationId'>
              <Header title="Recommendations" />
              <RecommendationPage />
            </Route>
            <Route path='/activities' exact>
              <Header title="Activities" />
              <ActivitiesPage />
            </Route>
            <Route path='/activities/:activityId'>
              <Header title="Activities" />
              <ActivityPage />
            </Route>
            <Route path='/reporting/report/:mechanismId/:reportId'>
              <Header title="Reporting" />
              <ReportPage />
            </Route>
            <Route path='/reporting'>
              <Header title="Reporting" />
              <Reporting />
            </Route>
            <Route path='/statistics'>
              <Header title="Statistics" />
              <Statistics />
            </Route>
            <Route path='/settings'>
              <Header title="Settings" />
              <Settings />
            </Route>
            <Route path='/profile'>
              <Header title="Profile" />
              <Profile />
            </Route>
            <Route path='*'>
              <Redirect to='/home' />
            </Route>
          </Switch>
        </div>
      </div >
    </>
  );
}

export default App;
