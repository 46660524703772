// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

/**
 * Properties:
 * @param currency: { id: number, name: string }
 * @param dialogVisible: bool
 * @param saveButtonLabel: string
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (id, name) => {}
 */
const CurrenciesDialog = (props) => {
  // states
  const [name, setName] = useState(props.currency.name);

  useEffect(() => {
    setName(props.currency.name);
  }, [props.currency.name])

  // events
  const saveClickHandler = () => {
    props.onSave(name);
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          < div className="p-field">
            <label htmlFor="name">Name</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id="name" type="text" />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CurrenciesDialog;