// react imports
import React, { useState, useEffect } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

// custom components
import StatusIcon from "../../../common/status-icon/StatusIcon";

// sample
import { MECHANISMS, INSTITUTIONS } from "../../../../apis/sampleData";

// styles
import "./ReportSidepanel.scss"

/**
 * 
 * @param report
 * @param wordCount: number
 * @returns 
 */
const ReportSidepanel = (props) => {
  // useEffect(() => {
  //   console.log(`wordCount has been changed: ${props.wordCount}`);
  // }, [props.wordCount]);

  return (
    <div className="single-element-sidepanel report">
      <Button
        label="Submit To OHCHR"
        onClick={() => console.log('Submit To OHCHR')}
      />

      <Button
        label="DOWNLOAD (.DOCX)"
        onClick={() => console.log('DOWNLOAD (.DOCX)')}
      />
      
      <div className="report__panel-block lead-institution">
        <title>Lead Institution</title>
        <div className="p-field">
          <Dropdown
            optionLabel="name"
            value={props.report.institution}
            options={INSTITUTIONS}
            onChange={(e) => console.log(e.value)}
            placeholder="Lead Institution"
          />
        </div>
      </div>

      <div className="report__panel-block respond-by">
        <title>Respond by</title>
        <label>{props.report.byDate}</label>
      </div>

      <div className="report__panel-block status">
        <title>Status</title>
        <div className="status-wrapper">
          <StatusIcon
            className={props.report.status.name.replaceAll(' ', '-').toLowerCase()}
            iconClassName={props.report.status.icon}
            color={props.report.status.color}
          />
          <label>{props.report.status.name}</label>
        </div>
      </div>

      <div className="report__panel-block word-limit">
        <title>Word limit</title>
        <label>
          {props.wordCount}/100
        </label>
      </div>

      <Button
        icon="las la-envelope"
        label="Contact Cedaw secretariat"
        className="p-button-text"
      />

      <Button
        icon="las la-th-list"
        label="View related recommendations"
        className="p-button-text"
      />

      <Button
        icon="las la-folder-open"
        label="View related documents"
        className="p-button-text"
      />
    </div>
  );
};

export default ReportSidepanel;