// react imports
import React, { useState } from "react";

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

// import custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import InstitutionTypeDialog from './intitution-type-dialog/InstitutionTypeDialog';
import InstitutionDialog from "./institution-dialog/InstitutionDialog";

// data imports
import { INSTITUTIONS } from '../../../apis/sampleData';
import { PAGINATOR_TEMPLATE } from '../../../apis/constants';

//import "./Institutions.scss"

const InstitutionsPage = () => {
  // institution type state
  const [institutionTypeDialogVisible, setInstitutionTypeDialogVisible] = useState(false);

  // new institution dialog state
  const [newInstitutionDialogVisible, setNewInstitutionDialogVisible] = useState(false);

  // edit institution dialog states
  const [editInstitutionDialogVisible, setEditInstitutionDialogVisible] = useState(false);
  const [editingInstitution, setEditingInstitution] = useState({ id: 0, name: '', acronym: '', institutionType: '' });

  // events
  const getInstitutionActionClickHandler = (institutionId) => {
    return (actionName) => {
      switch (actionName) {
        case 'Edit':
          const institutionToEdit = INSTITUTIONS.find(i => i.id === institutionId);
          setEditingInstitution({
            id: institutionToEdit.id,
            name: institutionToEdit.name,
            acronym: institutionToEdit.acronym,
            institutionType: institutionToEdit.institutionType
          });
          setEditInstitutionDialogVisible(true);
          break;
        case 'Delete':
          const institutionToDelete = INSTITUTIONS.find(i => i.id === institutionId);
          confirmDialog({
            message: `Are you sure you want to delete ${institutionToDelete.name}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { console.log('Deleted'); },
            reject: () => { }
          });
          break;
        default:
          break;
      }
    };
  };

  // templates
  const getActionMenuTemplate = rowData => {
    const actions = [
      { name: 'Edit', iconClass: "las la-edit" },
      { name: 'Delete', iconClass: "las la-trash" }
    ];

    return (
      <ActionMenu
        actions={actions}
        actionSelected={getInstitutionActionClickHandler(rowData.id)}
      />
    );
  };

  return (
    <div className="settings--tab-content">
      {/*<Editor style={{ height: '320px' }} placeholder="Additional information on implementation progress" />*/}

      <div className="settings--title-bar">
        <h1>Institutions</h1>
        <div className="settings--buttons">
          <Button
            icon="las la-th-list"
            label="Create institution type"
            className="p-button-text p-button-secondary"
            onClick={() => setInstitutionTypeDialogVisible(true)}
          />
          <Button
            icon="las la-plus-circle"
            label="New institution"
            className="p-button-text"
            onClick={() => setNewInstitutionDialogVisible(true)}
          />
        </div>
      </div>

      <InstitutionTypeDialog
        dialogVisible={institutionTypeDialogVisible}
        onClose={() => setInstitutionTypeDialogVisible(false)}
        onCreate={newInstitutionType => { setInstitutionTypeDialogVisible(false); console.log(newInstitutionType); }}
      />

      <InstitutionDialog
        institution={{ id: 0, name: '', acronym: '', institutionType: '' }}
        dialogVisible={newInstitutionDialogVisible}
        saveButtonLabel="Create"
        dialogHeader="NEW INSTITUTION"
        onClose={() => setNewInstitutionDialogVisible(false)}
        onSave={(id, institution, acronym, institutionType) => { setNewInstitutionDialogVisible(false); console.log(id, institution, acronym, institutionType); }}
      />

      <InstitutionDialog
        institution={editingInstitution}
        dialogVisible={editInstitutionDialogVisible}
        saveButtonLabel="Save"
        dialogHeader="EDIT INSTITUTION"
        onClose={() => setEditInstitutionDialogVisible(false)}
        onSave={(id, institution, acronym, institutionType) => { setEditInstitutionDialogVisible(false); console.log(id, institution, acronym, institutionType); }}
      />

      <DataTable
        value={INSTITUTIONS}
        paginator
        paginatorTemplate={PAGINATOR_TEMPLATE}
        rows={10}
        sortField="name"
        sortOrder={1}
      >
        <Column className="name" field="name" header="Name" sortable></Column>
        <Column field="acronym" header="Acronym" sortable></Column>
        <Column field="institutionType" header="InstitutionType" sortable></Column>
        <Column className="actions" header="Actions" body={getActionMenuTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default InstitutionsPage;