// react imports
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// import prime reacts
import { DataView } from 'primereact/dataview';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';

// custom components
import FilterSelect from "../../common/filter-select/FilterSelect";


// styles
import "./ActivitiesList.scss"
import { ScrollPanel } from "primereact/scrollpanel";

const activitySortList = ["By Implementation Status", "By Name", "By Institution"];

/**
 * Properties:
 * @param activities: { name: string, implementationStatus: IMPLEMENTATION_STATUS, leadInstitution: INSTITUTION, assignedToProject: boolean, linkedToRecommendation: boolean, linked: boolean}
 * @param activitiesListHeight: number - (if not set, the list will not have a fixed height)
 * @param activityClickable: boolean - if activity title is a link to the activity
 * @param exportAvailable: boolean
 * @param multipleAddingAvailable: boolean
 * @param multipleAddingLinkText: string (placeholder for number of selected: #NUMBER)
 * @param multipleAddingHandler: (ids) => {}
 * @param assignToProjectLinkAvailable: boolean
 * @param assigningToProjectHandler: (id) => {}
 * @param removingFromProjectHandler: (id) => {}
 * @param linkToRecommendationLinkAvailable: boolean
 * @param linkingToRecommendationHandler: (id) => {}
 * @param unlinkingFromRecommendationHandler: (id) => {}
 * @returns 
 */
const ActivitiesList = (props) => {
  let history = useHistory();

  // states
  const [sortOrder, setSortOrder] = useState(activitySortList[0])
  const [selectedIDsForMultipleAdding, setSelectedIDsForMultipleAdding] = useState([]);

  // events
  const getClickOnActivityCBHandler = activityId => () => {
    if (selectedIDsForMultipleAdding.indexOf(activityId) !== -1) {
      setSelectedIDsForMultipleAdding(selectedIDsForMultipleAdding.filter(id => id !== activityId));
    } else {
      setSelectedIDsForMultipleAdding([activityId, ...selectedIDsForMultipleAdding]);
    }
  };
  const clickOnActivityContent = activityId => {
    if (props.activityClickable) {
      history.push(`/activities/${activityId}`);
    }
  };

  // templates
  const listJSX = (
    <div className="p-dataview-content">
      {props.activities.map(activity => (
        <div className="p-col-12 nrtd-list-item" key={activity.id}>
          {props.multipleAddingAvailable && (
            <div className="nrtd-list-item--selection">
              <Checkbox
                onChange={getClickOnActivityCBHandler(activity.id)}
                checked={selectedIDsForMultipleAdding.indexOf(activity.id) !== -1}>

              </Checkbox>
            </div>
          )}

          <div
            className={`nrtd-list-item--content ${props.activityClickable ? 'clickable' : ''}`}
            onClick={() => clickOnActivityContent(activity.id)}
          >
            <title>
              {activity.name}
            </title>

            <div className="property-field">
              <div className="property-field-title">
                Implementation Status
              </div>
              <div className="property-field-value implementation-status">
                <div className={"status-bars " + activity.implementationStatus.replace(/ /g, '-').toLowerCase()}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                {activity.implementationStatus}
              </div>
            </div>

            <div className="property-field">
              <div className="property-field-title">
                Lead Institution
              </div>
              <div className="property-field-value">
                {activity.leadInstitution.name}
              </div>
            </div>
          </div>

          <div className="nrtd-list-item--links">
            {props.assignToProjectLinkAvailable && (
              <Button
                icon="las la-plus-circle"
                label={activity.assignedToProject ? "Remove from Project" : "Assign to Project"}
                className="p-button-text"
                onClick={() => {
                  activity.assignedToProject ?
                    props.removingFromProjectHandler(activity.id) :
                    props.assigningToProjectHandler(activity.id)
                }}
              />
            )}

            {props.linkToRecommendationLinkAvailable && (
              <Button
                icon="las la-link"
                label={activity.linkedToRecommendation ? "Unlink recommendation" : "Link recommendation"}
                className="p-button-text link-recommendation"
                onClick={() => {
                  activity.linkedToRecommendation ?
                    props.unlinkingFromRecommendationHandler(activity.id) :
                    props.linkingToRecommendationHandler(activity.id)
                }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="activity-list">
      <div className="p-dataview nrtd-list activity-list">
        <div className="nrtd-list-header">
          <div className="dataview-number-of-items">
            {props.activities.length} activities
          </div>
          <div className="dataview-list-sort-buttons">
            {props.multipleAddingAvailable && selectedIDsForMultipleAdding.length > 0 && (
              <Button
                icon="las la-plus-circle"
                label={props.multipleAddingLinkText.replace('#NUMBERS', selectedIDsForMultipleAdding.length)}
                className="p-button-text"
                onClick={() => props.multipleAddingHandler(selectedIDsForMultipleAdding)}
              />
            )}

            {props.exportAvailable && (
              <Button
                icon="las la-external-link-alt"
                label="Export"
                className="p-button-text p-button-secondary"
                onClick={() => console.log("export")}
              />
            )}

            <FilterSelect
              title="SORT"
              iconClass="las la-sort-amount-down"
              options={activitySortList}
              value={sortOrder}
              onSelectValue={setSortOrder}
            />
          </div>
        </div>

        {props.activitiesListHeight && (
          <ScrollPanel style={{ width: '100%', height: `${props.activitiesListHeight}px` }}>
            {listJSX}
          </ScrollPanel>
        )}

        {!props.activitiesListHeight && (listJSX)}
      </div>
    </div>
  );
};

export default ActivitiesList;