// react imports
import React, { useRef, useState } from "react";

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from "primereact/inputtext";

// import custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import DocumentDialog from "./document-dialog/DocumentDialog";

// data imports
import { PAGINATOR_TEMPLATE } from '../../../apis/constants';
import { DOCUMENTS } from '../../../apis/sampleData';


const emptyDocument = { name: '' };


const DocumentsPage = () => {
  // new document dialog state
  const [newDocumentDialogVisible, setNewDocumentDialogVisible] = useState(false);

  // edit document dialog states
  const [editDocumentDialogVisible, setEditDocumentDialogVisible] = useState(false);
  const [document, setDocument] = useState(emptyDocument);

  const [searchText, setSearchText] = useState('');

  // events
  const getAffectedPersonActionClickHandler = (name) => {
    return (actionName) => {
      switch (actionName) {
        case 'Edit':
          setDocument(DOCUMENTS.find(d => d.name === name));
          setEditDocumentDialogVisible(true);
          break;
        case 'Delete':
          confirmDialog({
            message: `Are you sure you want to delete ${name}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { console.log('Deleted'); },
            reject: () => { }
          });
          break;
        default:
          break;
      }
    };
  };

  // templates
  const getActionMenuTemplate = rowData => {
    const actions = [
      { name: 'Edit', iconClass: "las la-edit" },
      { name: 'Delete', iconClass: "las la-trash" }
    ];

    return (
      <ActionMenu
        actions={actions}
        actionSelected={getAffectedPersonActionClickHandler(rowData.name)}
      />
    );
  };

  // refs
  const searchRef = useRef();


  const filteredDocuments = DOCUMENTS.filter(d => {
    return d.name.toLowerCase().includes(searchText);
  });


  return (
    <div className="settings--tab-content documents">
      {/*<Editor style={{ height: '320px' }} placeholder="Additional information on implementation progress" />*/}

      <div className="settings--title-bar">
        <h1>Institutions</h1>
        <div className="settings--buttons">

          <div className="p-inputgroup search-inputgroup">
            <label className="p-inputgroup-addon" htmlFor="search-document">
              <i className="las la-search"></i>
            </label>
            <InputText
              placeholder="Search Document"
              type="text"
              id="search-document"
              ref={searchRef}
              onChange={e => setSearchText(e.target.value)}
            />
            {/* <Button type="button" onClick={e => console.log(searchRef)}>
              <i className="las la-arrow-right"></i>
            </Button> */}
          </div>
          <Button
            icon="las la-plus-circle"
            label="New Document"
            className="p-button-text"
            onClick={() => setNewDocumentDialogVisible(true)}
          />
        </div>
      </div>

      <DocumentDialog
        document={emptyDocument}
        dialogVisible={newDocumentDialogVisible}
        saveButtonLabel="Create"
        dialogHeader="NEW DOCUMENT"
        onClose={() => setNewDocumentDialogVisible(false)}
        onSave={(name) => setNewDocumentDialogVisible(false)}
      />

      <DocumentDialog
        document={document}
        dialogVisible={editDocumentDialogVisible}
        saveButtonLabel="Save"
        dialogHeader="EDIT DOCUMENT"
        onClose={() => setEditDocumentDialogVisible(false)}
        onSave={(name) => { setEditDocumentDialogVisible(false); console.log(name); }}
      />

      <DataTable
        value={filteredDocuments}
        paginator
        paginatorTemplate={PAGINATOR_TEMPLATE}
        rows={10}
        sortField="name"
        sortOrder={1}
      >
        <Column className="name" field="name" header="Name" sortable></Column>
        <Column className="actions" header="Actions" body={getActionMenuTemplate}></Column>
      </DataTable>
    </div >
  );
};

export default DocumentsPage;