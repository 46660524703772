// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';


// const imports
import { INSTITUTIONS, LANGUAGES, ROLES } from "../../../../apis/sampleData";

/**
 * Properties:
 * @param user: object {
 *  id: number, 
 *  role: string, 
 *  institution: string, 
 *  language: string,
 *  firstName: string,
 *  lastName: string,
 *  email: string,
 *  username: string,
 *  password: string,
 *  country: string
 * }
 * @param dialogVisible: bool
 * @param saveButtonLabel: string
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (id,role,institution,language,firstName,lastName,email,username,password,country) => {}
 */
const UserDialog = (props) => {
  // states
  const [role, setRole] = useState(props.user.role);
  const [institution, setInstitution] = useState(props.user.institution);
  const [language, setLanguage] = useState(props.user.language);
  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [email, setEmail] = useState(props.user.email);
  const [username, setUsername] = useState(props.user.username);
  const [password, setPassword] = useState(props.user.password);

  useEffect(() => {
    setRole(props.user.role);
    setInstitution(props.user.institution);
    setLanguage(props.user.language);
    setFirstName(props.user.firstName);
    setLastName(props.user.lastName);
    setEmail(props.user.email);
    setUsername(props.user.username);
    setPassword(props.user.password);
  }, [props.user]);

  // events
  const saveClickHandler = () => {
    props.onSave(props.user.id,
      role,
      institution,
      language,
      firstName,
      lastName,
      email,
      username,
      password,
    );
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '600px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="first-name">First Name</label>
            <InputText
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              id="first-name"
              type="text"
              style={{ width: '268px' }}
            />
          </div>
          <div className="p-field p-col">
            <label htmlFor="last-name">Last Name</label>
            <InputText
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              id="last-name"
              type="text"
              style={{ width: '268px' }}
            />
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="user-role">Select User Role</label>
            <Dropdown
              id="user-role"
              options={ROLES}
              value={role}
              onChange={(e) => setRole(e.value)}
              style={{ width: '268px' }}
            />
          </div>
          <div className="p-field p-col">
            <label htmlFor="institution">Select Institution</label>
            <Dropdown
              id="institution"
              options={INSTITUTIONS}
              optionLabel="name"
              optionValue="name"
              value={institution}
              onChange={(e) => setInstitution(e.value)}
              style={{ width: '268px' }}
            />
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="language">Select Language</label>
            <Dropdown
              id="language"
              options={LANGUAGES}
              value={language}
              onChange={(e) => setLanguage(e.value)}
              style={{ width: '268px' }}
            />
          </div>
          <div className="p-field p-col">
            <label htmlFor="email">Email</label>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              type="email"
              style={{ width: '268px' }}
            />
          </div>
        </div>

        <h5>Login details</h5>

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="username">Username</label>
            <InputText value={username} onChange={(e) => setUsername(e.target.value)} id="username" type="email" />
          </div>
          <div className="p-field p-col">
            <label htmlFor="password">Password</label>
            <Password value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UserDialog;