// react imports
import React from 'react';
import { NavLink } from 'react-router-dom';

// primereact components
import { Chart } from 'primereact/chart';

// style
import "./OverviewBar.scss";

/**
 * Props properties:
 * @param title: string
 * @param rightTopLinkText: string
 * @param rightTopLink: string
 * @param fillValue: number
 * @param totalValue: number
 * @param barFillColor: string (css color)
 * @param barBackgroundColor: string (css color)
 * @param fillCaption: string (default: "Assigned to me")
 * @param totalCaption: string (default: "Total")
 * @returns 
 */
const OverviewBar = React.memo(props => {
  const fillCaption = props.fillCaption ? props.fillCaption : "Assigned to me";
  const totalCaption = props.totalCaption ? props.totalCaption : "Total";
  const barFillColor = props.barFillColor || "#106fa8";
  const barBackgroundColor = props.barBackgroundColor || "#106fa8";

  const secondBarValue = props.totalValue - props.fillValue;

  const chartData = {
    labels: [''],
    datasets: [
      {
        type: 'bar',
        label: fillCaption,
        backgroundColor: barFillColor,
        data: [props.fillValue],
        barThickness: 32,
      },
      {
        type: 'bar',
        label: totalCaption,
        backgroundColor: barBackgroundColor,
        data: [secondBarValue],
        barThickness: 32,
      }
    ]
  };

  let chartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
      legend: {
        labels: {
          color: '#495057',
          padding: 10
        },
        position: window.innerWidth > 768 ? 'right' : 'top',
        onClick: null
      },
      tooltip: {
        yAlign: 'bottom',
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            let value = label.toLowerCase() === 'total' ? props.totalValue : context.raw

            if (label && context.parsed.x !== null) {
              label += ': ' + value;
            }

            return ' ' + label;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        position: 'top',
        ticks: {
          color: '#495057',
          callback: function (val, index) {
            // Hide the label of every 2nd dataset
            return index % 2 === 0 ? this.getLabelForValue(val) : '';
          },
        },
        grid: {
          color: function (context) {
            return context.index % 2 === 0 ? '#ebedef' : 'transparent';
          }
        }
      },
      y: {
        stacked: true,
        position: 'top',
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  return (
    <div className="nrtd-overview-bar">
      <div className="title-bar">
        <h2>{props.title}</h2>
        <NavLink to={props.rightTopLink}>
          {props.rightTopLinkText}
        </NavLink>
      </div>
      <div className="bar-wrapper">
        <Chart
          type="bar"
          data={chartData}
          options={chartOptions}
        />
      </div>
    </div>
  );
});

export default OverviewBar;