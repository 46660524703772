// importa reacts
import React from "react";

// primereact imports
import { Dropdown } from 'primereact/dropdown';

// helper funcs
import { hexToRGBA } from "../../../apis/helperFunc";

// styles
import "./StatusIcon.scss";
import { registerables } from "chart.js";

/**
 * Properties:
 * @param className: string
 * @param iconClassName: string
 * @param color: string (hex css color)
 * @param backgroundOpacity?: string (between 0-1) (default: 0.15)
 * @param size?: string, width and height (default: '30px')
 * @param fontSize?: string (default: 22px)
 * @param tooltip?: string
 */
const StatusIcon = (props) => {
  const defaultProps = {
    backgroundOpacity: '0.15',
    size: '30px',
    fontSize: '21px'
  };

  let computedProps = {...defaultProps, ...props };
  
  computedProps.backgroundColor = hexToRGBA(computedProps.color, computedProps.backgroundOpacity);

  return (
    <span 
      className={`status-icon ${computedProps.className}`}
      style={{ 
        backgroundColor: computedProps.backgroundColor,
        width: computedProps.size,
        height: computedProps.size
      }}
      data-tip={props.tooltip}
    >
      <i className={props.iconClassName} style={{ color: computedProps.color, fontSize: computedProps.fontSize }}></i>
    </span>
  );
};

export default StatusIcon;