// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

// custom components
import AttachmentDialog from "../../../common/dialogs/attachment-dialog/AttachmentDialog";
import LinkActivityModal from "../../../common/modals/link-activity-modal/LinkActivityModal";
import AssignToProjectModal from "../../../common/modals/assign-to-project-modal/AssignToProjectModal";
import CreateActivityModal from "../../../common/modals/create-activity-modal/CreateActivityModal";


// sample
import { PROJECTS, RECOMMENDATIONS } from "../../../../apis/sampleData";

// styles
import "./RecommendationSidepanel.scss"

/**
 * 
 * @param recommendation
 * @returns 
 */
const RecommendationSidepanel = (props) => {
  // states
  const [projectDialogVisible, setProjectDialogVisible] = useState(false);
  const [activityDialogVisible, setActivityDialogVisible] = useState(false);
  const [createActivityModalVisible, setCreateActivityModalVisible] = useState(false);

  // consts
  const projects = props.recommendation.projectIds.map(projectId => PROJECTS.find(p => p.id === projectId));
  const activities = props.recommendation.activityIds.map(activityId => RECOMMENDATIONS.find(r => r.id === activityId));

  return (
    <div className="single-element-sidepanel">
      <Button
        icon="las la-plus"
        label="Assign to project"
        onClick={() => setProjectDialogVisible(true)}
      />

      <AssignToProjectModal
        dialogVisible={projectDialogVisible}
        onClose={() => setProjectDialogVisible(false)}
        onSave={() => setProjectDialogVisible(false)}
      />

      <div className="single-element-sidepanel-list projects">
        <title>In Projects</title>
        {projects.map(project => (
          <div className="sidepanel-list-item project-name-item" key={project.id}>
            <div className="item-content">
              {project.name}
            </div>
            <div className="remove-button" onClick={() => console.log(project.id)}>
              <i className="las la-times"></i>
            </div>
          </div>
        ))}
      </div>

      <div className="single-element-sidepanel-list activities">
        <title>Activities</title>
        {activities.map(activity => (
          <div className="sidepanel-list-item" key={activity.id}>
            <div className="item-content">
              <i className="las la-clipboard-check"></i>
              <span>{activity.name}</span>
            </div>
            <div className="remove-button" onClick={() => console.log(activity.id)}>
              <i className="las la-times"></i>
            </div>
          </div>
        ))}

        <Button
          icon="las la-clipboard-check"
          label="Link activitiy"
          className="p-button-text link-activity"
          onClick={() => setActivityDialogVisible(true)}
        />

        <LinkActivityModal
          dialogVisible={activityDialogVisible}
          onClose={() => setActivityDialogVisible(false)}
          onSave={() => setActivityDialogVisible(false)}
        />

      </div>

      <Button
        icon="fas fa-clipboard-check"
        label="Create Activity"
        className="create-activity"
        onClick={() => setCreateActivityModalVisible(true)}
      />

      <CreateActivityModal
        onSave={() => setCreateActivityModalVisible(false)}
        onClose={() => setCreateActivityModalVisible(false)}
        modalVisible={createActivityModalVisible}
      />
    </div>
  );
};

export default RecommendationSidepanel;