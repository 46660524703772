// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

// custom components
import RecommendationList from "../../../recommendations/recommendation-list/RecommendationList";
import RecommendationsFilterPanel from "../../../recommendations/recommendations-filter-panel/RecommendationsFilterPanel";
import SelectedItems from "../../../common/selected-items/SelectedItems";

// styles
// import "./DropdownDialog.scss";


/**
 * Props properties:
 * @param dialogVisible: boolean
 * @param onClose: () => {}
 * @returns 
 */
const LinkRecommendationModal = (props) => {

  // templates
  const footerTemplate = (
    <>
      <Button label="Add" onClick={props.onClose} />
      <Button label="Cancel" onClick={props.onClose} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header="Link Recommendation"
      visible={props.dialogVisible}
      style={{ width: '1200px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <SelectedItems
        title="Selected Recommendations"
        selectedItems={[{ id: 0, name: "Elaboration of the legislative amendments" }, { id: 1, name: "Training on effective investigation of torture" },]}
        removeItem={id => console.log('remove item ' + id)}
      />

      <RecommendationsFilterPanel extended={false} />

      <RecommendationList
        recommendationListHeight="274"
        recommendationClickable={false}
        isLinkable={true}
        isMultipleAddingAvailable={true}
        multipleAddingLinkText="ADD SELECTED (#NUMBERS) TO PROJECT"
      />
    </Dialog>
  );
};

export default LinkRecommendationModal;