// react imports
import React, { useState } from "react";

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

// import custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import CurrencyDialog from "./currency-dialog/CurrencyDialog";

// data imports
import { PAGINATOR_TEMPLATE } from '../../../apis/constants';
import { CURRENCIES } from '../../../apis/sampleData';


const emptyCurrency = { name: '' };


const AffectedPersonsPage = (props) => {
  // new currency dialog state
  const [newCurrencyDialogVisible, setNewCurrencyDialogVisible] = useState(false);

  // edit currency dialog states
  const [editCurrencyDialogVisible, setEditCurrencyDialogVisible] = useState(false);
  const [editingCurrency, setEditingCurrency] = useState(emptyCurrency);

  // events
  const getCurrencyActionClickHandler = (id) => {
    return (actionName) => {
      switch (actionName) {
        case 'Edit':
          setEditingCurrency(CURRENCIES.find(c => c.id === id));
          setEditCurrencyDialogVisible(true);
          break;
        case 'Delete':
          confirmDialog({
            message: `Are you sure you want to delete ${CURRENCIES.find(c => c.id === id).name}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { console.log('Deleted'); },
            reject: () => { }
          });
          break;
        default:
          break;
      }
    };
  };

  // templates
  const getActionMenuTemplate = rowData => {
    const actions = [
      { name: 'Edit', iconClass: "las la-edit" },
      { name: 'Delete', iconClass: "las la-trash" }
    ];

    return (
      <ActionMenu
        actions={actions}
        actionSelected={getCurrencyActionClickHandler(rowData.id)}
      />
    );
  };


  return (
    <div className="settings--tab-content currencies">
      {/*<Editor style={{ height: '320px' }} placeholder="Additional information on implementation progress" />*/}

      <div className="settings--title-bar">
        <h1>Currencies</h1>
        <div className="settings--buttons">
          <Button
            icon="las la-plus-circle"
            label="New Currency"
            className="p-button-text"
            onClick={() => setNewCurrencyDialogVisible(true)}
          />
        </div>
      </div>

      <CurrencyDialog
        currency={emptyCurrency}
        dialogVisible={newCurrencyDialogVisible}
        saveButtonLabel="Create"
        dialogHeader="NEW CURRENCY"
        onClose={() => setNewCurrencyDialogVisible(false)}
        onSave={(name) => { setNewCurrencyDialogVisible(false); console.log(name); }}
      />

      <CurrencyDialog
        currency={editingCurrency}
        dialogVisible={editCurrencyDialogVisible}
        saveButtonLabel="Save"
        dialogHeader="EDIT CURRENCY"
        onClose={() => setEditCurrencyDialogVisible(false)}
        onSave={(name) => { setEditCurrencyDialogVisible(false); console.log(name); }}
      />

      <DataTable
        value={CURRENCIES}
        paginator
        paginatorTemplate={PAGINATOR_TEMPLATE}
        rows={10}
        sortField="id"
        sortOrder={1}
      >
        <Column className="id" field="id" header="ID" sortable></Column>
        <Column className="name" field="name" header="Name" sortable></Column>
        <Column className="actions" header="Actions" body={getActionMenuTemplate}></Column>
      </DataTable>
    </div >
  );
};

export default AffectedPersonsPage;