// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

// custom components
import ActivitiesFilterPanel from "../../../activities/activities-filter-panel/ActivitiesFilterPanel";
import ActivitiesList from "../../../activities/activities-list/ActivitiesList";
import SelectedItems from "../../../common/selected-items/SelectedItems";

// sample data
import { ACTIVITIES } from "../../../../apis/sampleData";

// style
import "./LinkActivityModal.scss";

/**
 * Props properties:
 * @param dialogVisible: boolean
 * @param onClose: () => {}
 * @returns 
 */
const LinkActivityModal = (props) => {
  // states
  const [activities, setActivities] = useState(ACTIVITIES);

  // templates
  const footerTemplate = (
    <>
      <Button label="Add" onClick={props.onClose} />
      <Button label="Cancel" onClick={props.onClose} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header="Link Activity"
      visible={props.dialogVisible}
      style={{ width: '1200px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
      className="link-activity-modal"
    >
      <SelectedItems
        title="Selected activities"
        selectedItems={[{ id: 0, name: "Elaboration of the legislative amendments" }, { id: 1, name: "Training on effective investigation of torture" },]}
        removeItem={id => console.log('remove item ' + id)}
      />

      <ActivitiesFilterPanel />

      <ActivitiesList
        activities={activities}
        activitiesListHeight={330}
        sortBy="IMPLEMENTATION STATUS"
        exportAvailable={true}
        multipleAddingAvailable={true}
        multipleAddingLinkText={"add (#NUMBERS) activity"}
        multipleAddingHandler={activityIDs => console.log("multiple add handler", activityIDs)}
        assignToProjectLinkAvailable={false}
        linkToRecommendationLinkAvailable={false}
      />
    </Dialog>
  );
};

export default LinkActivityModal;