export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const COLORS = {
  primaryBlue: '#106FA8',
  primaryOrange: '#FFAB2B',
  primaryGreen: '#6DD230',
  lightBlue: '#DCEAF2',
  lightGreen: '#D2F1C0',
  bgGray1: '#F8FAFB',
  bgGray2: '#F2F4F6'
};

export const PAGINATOR_TEMPLATE = {
  layout: 'PrevPageLink PageLinks NextPageLink',
  'PrevPageLink': (options) => {
    return (
      <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
        <i className="las la-angle-left"></i>
        Prev
      </button>
    )
  },
  'NextPageLink': (options) => {
    return (
      <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
        Next
        <i className="las la-angle-right"></i>
      </button>
    )
  }
};