// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

// const imports
import { INSTITUTION_TYPES } from "../../../../apis/sampleData";

/**
 * Properties:
 * @param institution: {id: number, name: string, acronym: string, institutionType: string}
 * @param dialogVisible: bool
 * @param saveButtonLabel: string
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (id, institution, acronym, institutionType) => {}
 */
const InstitutionDialog = (props) => {
  // states
  const [institutionName, setInstitutionName] = useState(props.institution.name);
  const [acronym, setAcronym] = useState(props.institution.acronym);
  const [institutionType, setInstitutionType] = useState(props.institutionType);

  useEffect(() => {
    setInstitutionName(props.institution.name);
    setAcronym(props.institution.acronym);
    setInstitutionType(props.institution.institutionType);
  }, [props.institution.name, props.institution.acronym, props.institution.institutionType])

  // events
  const saveClickHandler = () => {
    props.onSave(props.institution.id, institutionName, acronym, institutionType);
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          < div className="p-field">
            <label htmlFor="institution-name">Institution</label>
            <InputText value={institutionName} onChange={(e) => setInstitutionName(e.target.value)} id="institution-name" type="text" />
          </div>
          <div className="p-field">
            <label htmlFor="institution-acronym">Acronym</label>
            <InputText value={acronym} onChange={(e) => setAcronym(e.target.value)} id="institution-acronym" type="text" />
          </div>
          <div className="p-field">
            <label htmlFor="institution-type">Institution Type</label>
            <Dropdown id="institution-type" options={INSTITUTION_TYPES} value={institutionType} onChange={(e) => setInstitutionType(e.value)} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InstitutionDialog;