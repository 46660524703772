// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";

// sample
import { THEMES } from "../../../../apis/sampleData";

/**
 * Properties:
 * @param theme: { parentName: string, name: string }
 * @param dialogVisible: bool
 * @param saveButtonLabel: string
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (parentName, name) => {}
 */
const ThemeDialog = (props) => {
  // states
  const [name, setName] = useState(props.theme.name);
  const [parentName, setParentName] = useState(props.theme.parentName);

  useEffect(() => {
    setName(props.theme.name);
    setParentName(props.theme.parentName);
  }, [props.theme.name, props.theme.parentName])

  // events
  const saveClickHandler = () => {
    props.onSave(parentName, name);
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  const themeParents = [];
  THEMES.forEach(theme => {
    if (!themeParents.includes(theme.parentName)) {
      themeParents.push(theme.parentName);
    }
  });

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="mechanism-type">Parent Theme (Optional)</label>
            <Dropdown
              id="parent-theme"
              options={themeParents} value={parentName} onChange={(e) => setParentName(e.value)} />
          </div>
          <div className="p-field">
            <label htmlFor="name">Name</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id="name" type="text" />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ThemeDialog;