// react imports
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';

// 3rd party components
import ReactTooltip from 'react-tooltip';
import { Parser } from 'html-to-react';
import { Collapse } from "react-collapse";

// custom components
import TextInputDialog from "../../../common/dialogs/text-input-dialog/TextInputDialog";
import TextAreaDialog from "../../../common/dialogs/textarea-dialog/TextAreaDialog";
import LinkRecommendationModal from "../../../common/modals/link-recommendation-modal/LinkRecommendationModal";
import CollapseTrigger from '../../../common/collapse-trigger/CollapseTrigger';
import ActionMenu from "../../../common/action-menu/ActionMenu";

// sample
import { ACTIVITIES, IMPLEMENTATION_STATUSES, INSTITUTIONS, CURRENCIES, MECHANISMS } from "../../../../apis/sampleData";
import { getImplementationStatusTemplate } from "../../../../apis/temlates";

// helper functions
import { stripHTMLTags } from "../../../../apis/helperFunc";

// styles
import "./ReportDetailsContent.scss"

/**
 * 
 * @param reportTitle: string
 * @param wordCountChanged: (newWordCount:number) => {}
 * @returns 
 */
const ReportDetailsContent = (props) => {
  const params = useParams();

  // states
  const [recommendationDialogVisible, setRecommendationDialogVisible] = useState(false);
  const [points, setPoints] = useState([]);

  // content
  const report = useMemo(() => {
    const mechanism = MECHANISMS.find(m => m.id === params.mechanismId);
    if (!mechanism) {
      throw new Error(`There are no mechanism with id ${params.mechanismId}`);
      return;
    }

    const report = mechanism.requests.find(r => r.id === parseInt(params.reportId));
    if (!report) {
      throw new Error(`There are no report with id ${params.reportId}`);
      return;
    }

    setPoints(report.points);

    return report;
  }, [params.mechanismId, params.reportId]);


  // helper functions
  const viewRecommendationClicked = ({pointId}) => {
    const newPoints = [...points];

    // get the mechanism object
    const point = newPoints.find(p => p.id === pointId);

    // change the opened property
    point.opened = !point.opened;

    setPoints(newPoints);
  };
  const commentChanged = (text, pointId) => {
    /*** CHARACTER COUNT ***/
    const newPoints = [...points];

    // get the mechanism object
    const point = newPoints.find(p => p.id === pointId);

    // change the opened property
    point.comment = text;

    setPoints(newPoints);



    /*** CALCULATE GLOBAL WORD COUNT ***/
    let wordCount = 0;
    
    points.forEach(point => {
      if (typeof point.comment !== 'string' || point.comment.length === 0) {
        return;
      }

      let onlyText = stripHTMLTags(point.comment).trim();

      wordCount += onlyText.split(' ').length;
    });

    props.wordCountChanged(wordCount);
  };

  // templates
  const getTitle = point => {
    const titleHelper = point.titleHelper && (<i className="las la-question-circle" data-tip={point.titleHelper} data-for="point-title"></i>);

    return (<h3>{point.title} {titleHelper}</h3>);
  };
  const getSubtitle = point => {
    const subtitleHelper = point.subtitleHelper && (<i className="las la-question-circle" data-tip={point.subtitleHelper} data-for="point-subtitle"></i>);

    return (<h4>{point.subtitle} {subtitleHelper}</h4>);
  };

  // events
  const addPoint = () => {
    setPoints([...points, {
      id: Math.max(...points.map(p => p.id)) + 1,
      title: "I. Lorem ipsum dolor sit amet, vim novum consul ad",
      titleHelper: 'At sed tibique accusam commune.',
      description: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.</p>",
      comment: '',
      opened: false,
    }]);

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 300);
  };


  return (
    <div className="single-element-tab-content report details-content">
      <h2>{report.title}</h2>

      <div className="description">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. 
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. 
        </p>
      </div>

      <div className="report-points">
        {points.map(point => (
          <div key={point.id} className="point">
            <ActionMenu
              actions={[ { 
                name: 'Activity permissions',
                iconClass: 'las la-trash-alt'
              } ]}
              actionSelected={(selectedActionName => console.log(selectedActionName))}
              customPanelClassName="blue-links"
            />
            
            {point.title && getTitle(point)}
            
            {point.subtitle && getSubtitle(point)}

            {Parser().parse(point.description)}

            <Editor
              style={{ height: '100px' }}
              placeholder="Lorem ipsum dolor sit amet"
              value={point.comment}
              onTextChange={(e) => commentChanged(e.htmlValue, point.id)}
            />

            <div className="point__comment-char-num">
              {stripHTMLTags(point.comment).length}/500
            </div>

            <div className="point-buttons">
              <a className="blue-button" onClick={() => { console.log('SAVE'); }}>
                SAVE
              </a>

              <CollapseTrigger
                className="recommendation-collapse-trigger"
                opened={point.opened}
                triggerPropertyObject={{ pointId: point.id }}
                stateChanged={viewRecommendationClicked}
                arrowOnRight={true}
              >
                <Button
                  icon="las la-th-list"
                  label="View related recommendations"
                  className="p-button-text"
                />
              </CollapseTrigger>
            </div>

            <Collapse 
              isOpened={point.opened} 
              theme={{ collapse: 'linked-recommendations-collapse', content: 'linked-recommendations-wrapper' }}
            >
              <header>Linked Recommendations</header>

              <div className="recommendations">
                <div className="recommendation">
                  <div className="item-content">
                    <i className="las la-list"></i>
                    <span>43. The Committee notes that the adherence of the State party to the nine major international human rights instruments would enhance the enjoyment by women of their human rights and fundamental freedoms in all aspects of life. The Committee therefore encourages the State party to consider ratifying the International Convention for the Protection of All Persons from Enforced Disappearance and the International Convention on the Protection of the Rights of All Migrant Workers and Members of Their Families, to which it is not yet a party.</span>
                  </div>
                  <div className="remove-button">
                    <i className="las la-times"></i>
                  </div>
                </div>
                <div className="recommendation">
                  <div className="item-content">
                    <i className="las la-list"></i>
                    <span>45. The Committee invites the State party to submit its tenth periodic report in March 2020.</span>
                  </div>
                  <div className="remove-button">
                    <i className="las la-times"></i>
                  </div>
                </div>
              </div>

              <Button
                icon="las la-link"
                label="Link recommendation"
                className="p-button-text link-recommendation"
                onClick={() => setRecommendationDialogVisible(true)}
              />
            </Collapse>
          </div>
        ))}

        <LinkRecommendationModal
          dialogVisible={recommendationDialogVisible}
          onClose={() => setRecommendationDialogVisible(false)}
        />

        {/* <div className="point">
          <h3>I. General situation of women’s human rights in the country <i className="las la-question-circle" data-tip="Lorem ipsum"></i></h3>
  
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.</p>
  
          <Editor
            style={{ height: '100px' }}
            placeholder="Lorem ipsum dolor sit amet"
          />

          <div className="point-buttons">
            <a className="blue-button" onClick={() => { console.log('SAVE'); }}>
              SAVE
            </a>

            <div className="recommendation-collapse-trigger">
              <Button
                icon="las la-th-list"
                label="View related recommendations"
                className="p-button-text"
                onClick={() => console.log("View related recommendations")}
              />

              <i className="las la-angle-down"></i>
            </div>
          </div>

          <div className="linked-recommendations-wrapper">
            <header>Linked Recommendations</header>

            <div className="recommendations">
              <div className="recommendation">
                <div className="item-content">
                  <i className="las la-list"></i>
                  <span>43. The Committee notes that the adherence of the State party to the nine major international human rights instruments would enhance the enjoyment by women of their human rights and fundamental freedoms in all aspects of life. The Committee therefore encourages the State party to consider ratifying the International Convention for the Protection of All Persons from Enforced Disappearance and the International Convention on the Protection of the Rights of All Migrant Workers and Members of Their Families, to which it is not yet a party.</span>
                </div>
                <div className="remove-button">
                  <i className="las la-times"></i>
                </div>
              </div>
              <div className="recommendation">
                <div className="item-content">
                  <i className="las la-list"></i>
                  <span>45. The Committee invites the State party to submit its tenth periodic report in March 2020.</span>
                </div>
                <div className="remove-button">
                  <i className="las la-times"></i>
                </div>
              </div>
            </div>

            <Button
              icon="las la-link"
              label="Link recommendation"
              className="p-button-text link-recommendation"
              onClick={() => setRecommendationDialogVisible(true)}
            />

            <LinkRecommendationModal
              dialogVisible={recommendationDialogVisible}
              onClose={() => setRecommendationDialogVisible(false)}
            />

          </div>
        </div>

        <div className="point">
          <h3>I. General situation of women’s human rights in the country <i className="las la-question-circle" data-tip="Lorem ipsum"></i></h3>

          <h4>1. Right to education <i className="las la-question-circle" data-tip="Lorem ipsum"></i></h4>

          <p><strong>Question from Committee: What has been achieved in the area of eduaction?</strong></p>
  
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.</p>
  
          <Editor
            style={{ height: '100px' }}
            placeholder="Lorem ipsum dolor sit amet"
          />

          <div className="point-buttons">
            <a className="blue-button" onClick={() => { console.log('SAVE'); }}>
              SAVE
            </a>

            <div className="recommendation-collapse-trigger">
              <Button
                icon="las la-th-list"
                label="View related recommendations"
                className="p-button-text"
                onClick={() => console.log("View related recommendations")}
              />

              <i className="las la-angle-down"></i>
            </div>
          </div>

          <div className="linked-recommendations-wrapper">
            <header>Linked Recommendations</header>

            <div className="recommendations">
              <div className="recommendation">
                <div className="item-content">
                  <i className="las la-list"></i>
                  <span>43. The Committee notes that the adherence of the State party to the nine major international human rights instruments would enhance the enjoyment by women of their human rights and fundamental freedoms in all aspects of life. The Committee therefore encourages the State party to consider ratifying the International Convention for the Protection of All Persons from Enforced Disappearance and the International Convention on the Protection of the Rights of All Migrant Workers and Members of Their Families, to which it is not yet a party.</span>
                </div>
                <div className="remove-button">
                  <i className="las la-times"></i>
                </div>
              </div>
              <div className="recommendation">
                <div className="item-content">
                  <i className="las la-list"></i>
                  <span>45. The Committee invites the State party to submit its tenth periodic report in March 2020.</span>
                </div>
                <div className="remove-button">
                  <i className="las la-times"></i>
                </div>
              </div>
            </div>

            <Button
              icon="las la-link"
              label="Link recommendation"
              className="p-button-text link-recommendation"
              onClick={() => setRecommendationDialogVisible(true)}
            />

            <LinkRecommendationModal
              dialogVisible={recommendationDialogVisible}
              onClose={() => setRecommendationDialogVisible(false)}
            />

          </div>
        </div>

        <div className="point">
          <h4>2. Access to the labout market <i className="las la-question-circle" data-tip="Lorem ipsum"></i></h4>

          <p><strong>Question from Committee: What has been achieved in the area of eduaction?</strong></p>
  
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.</p>
  
          <Editor
            style={{ height: '100px' }}
            placeholder="Lorem ipsum dolor sit amet"
          />

          <div className="point-buttons">
            <a className="blue-button" onClick={() => { console.log('SAVE'); }}>
              SAVE
            </a>

            <div className="recommendation-collapse-trigger">
              <Button
                icon="las la-th-list"
                label="View related recommendations"
                className="p-button-text"
                onClick={() => console.log("View related recommendations")}
              />

              <i className="las la-angle-down"></i>
            </div>
          </div>

          <div className="linked-recommendations-wrapper">
            <header>Linked Recommendations</header>

            <div className="recommendations">
              <div className="recommendation">
                <div className="item-content">
                  <i className="las la-list"></i>
                  <span>43. The Committee notes that the adherence of the State party to the nine major international human rights instruments would enhance the enjoyment by women of their human rights and fundamental freedoms in all aspects of life. The Committee therefore encourages the State party to consider ratifying the International Convention for the Protection of All Persons from Enforced Disappearance and the International Convention on the Protection of the Rights of All Migrant Workers and Members of Their Families, to which it is not yet a party.</span>
                </div>
                <div className="remove-button">
                  <i className="las la-times"></i>
                </div>
              </div>
              <div className="recommendation">
                <div className="item-content">
                  <i className="las la-list"></i>
                  <span>45. The Committee invites the State party to submit its tenth periodic report in March 2020.</span>
                </div>
                <div className="remove-button">
                  <i className="las la-times"></i>
                </div>
              </div>
            </div>

            <Button
              icon="las la-link"
              label="Link recommendation"
              className="p-button-text link-recommendation"
              onClick={() => setRecommendationDialogVisible(true)}
            />

            <LinkRecommendationModal
              dialogVisible={recommendationDialogVisible}
              onClose={() => setRecommendationDialogVisible(false)}
            />

          </div>
        </div> */}
      </div>

      <Button
        icon="las la-plus-circle"
        label="Add more"
        className="p-button-text add-more"
        onClick={addPoint}
      />

      <ReactTooltip
        place="top"
        type="light"
        html={true}
        backgroundColor='#ECECEC'
        arrowColor='#ECECEC'
        color='black'
        id="point-title"
      />

      <ReactTooltip
        place="top"
        type="light"
        html={true}
        backgroundColor='#ECECEC'
        arrowColor='#ECECEC'
        color='black'
        id="point-subtitle"
      />
    </div>
  );
};

export default ReportDetailsContent;