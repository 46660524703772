// react imports
import { React, useState } from 'react';

// primereact imports
import { Chart } from 'primereact/chart';

// consts
import { COLORS } from '../../../../apis/constants';

// styles
import './StatisticsPieChart.scss';



/**
 * Props properties:
 * @param props.title: string
 */
const StatisticsPieChart = props => {
  const chartData = {
    labels: ['Lorem', 'Lorem Ipsum', 'Lorem Ipsum dolor'],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: [
          '#DADDE0',
          COLORS.lightBlue,
          COLORS.primaryBlue
        ],
        hoverBackgroundColor: [
          '#C2C4C6',
          "#B7C3C9",
          "#0E6393"
        ]
      }
    ]
  };

  const pieOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#98A9BC',
          padding: 10,
          font: {
            family: 'Rubik',
            size: 14,
            weight: 500
          },
        },
        position: 'bottom',
        onClick: null
      },
      tooltip: {
        yAlign: 'bottom',
        xAlign: 'center',
        position: 'nearest',
        callbacks: {
          label: function (context) {
            console.log(context);
            return context.label.toUpperCase() + ': ' + context.raw;
          },
          title: () => ''
        },
        bodyColor: '#E8ECEF',
        bodyFont: {
          family: 'Rubik',
          size: 12,
          weight: 500
        },
        displayColors: false
      }
    }
  };

  return (
    <div className="statistics-pie-chart">
      <div className="statistics-chart--title-row">
        <div className="statistics-chart--title">
          {props.title}
        </div>
      </div>

      <Chart
        type="pie"
        data={chartData}
        options={pieOptions}
        style={{ position: 'relative', width: '394px' }}
      />
    </div>
  );
};

export default StatisticsPieChart;