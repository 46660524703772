// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Calendar } from "primereact/calendar";

// samples
import { RECOMMENDING_BODIES } from "../../../../apis/sampleData";

// styles
import "./ImportRecommendationsModal.scss"

/**
 * Props:
 * @param onClose: () => {}
 * @param modalVisible: boolean
 * 
 * @returns 
 */
const ImportRecommendationsModal = (props) => {
  // states
  const [documentTitle, setDocumentTitle] = useState('');
  const [recommendingBody, setRecommendingBody] = useState('');
  const [date, setDate] = useState('');
  const [documentLink, setDocumentLink] = useState('');
  const [text, setText] = useState('');

  // templates
  const footerTemplate = (
    <>
      <Button label="Create" onClick={props.onClose} />
      <Button label="Cancel" onClick={props.onClose} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={'Import recommendations'}
      visible={props.modalVisible}
      style={{ width: '760px' }}
      onHide={props.onClose}
      footer={footerTemplate}
      dismissableMask={true}
      className="import-recommendations-modal"
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <label htmlFor="document-title">Document title or code</label>
            <InputText value={documentTitle} onChange={(e) => setDocumentTitle(e.target.value)} id="document-title" type="text" />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="recommending-body">Recommending body</label>
            <Dropdown
              id="recommending-body"
              options={RECOMMENDING_BODIES}
            />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="date">Date</label>
            <Calendar
              onChange={(e) => setDate(e.value)}
              showIcon
              icon="lar la-calendar"
              placeholder="Please select date"
            />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="document-link">Document link</label>
            <InputText
              value={documentLink}
              onChange={(e) => setDocumentLink(e.target.value)}
              id="document-link"
              type="text"
            />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="attachments">Attachments</label>
            <FileUpload
              name="attachment[]"
              url="./upload"
              multiple
              mode="basic"
              chooseLabel=""
              uploadLabel=""
              cancelLabel=""
            />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="text">Text area</label>
            <InputText
              value={text}
              onChange={(e) => setText(e.target.value)}
              id="text"
              type="text"
            />
            <Button
              icon="las la-plus-circle"
              label="Add text area"
              className="p-button-text add-text-area"
              onClick={() => console.log('Add text area')}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ImportRecommendationsModal;