// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";

// samples
import { INSTITUTIONS, PROJECTS } from "../../../../apis/sampleData";

// styles
import "./CreateActivityModal.scss"

/**
 * Props:
 * @param onSave: (name:string, description: string, leadInstitutionId: string, projectId: number) => {}
 * @param onClose: () => {}
 * @param modalVisible: boolean
 * 
 * @returns 
 */
const CreateActivityModal = (props) => {
  // states
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [leadInstitutionId, setLeadInstitutionId] = useState('');
  const [projectId, setProjectId] = useState('');

  // events
  const saveClickHandler = () => {
    props.onSave(name, name, leadInstitutionId, projectId);
  };

  // templates
  const footerTemplate = (
    <>
      <Button label="Create" onClick={saveClickHandler} />
      <Button label="Cancel" onClick={props.onClose} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={'Create Activity'}
      visible={props.modalVisible}
      style={{ width: '760px' }}
      onHide={props.onClose}
      footer={footerTemplate}
      dismissableMask={true}
      className="create-activity-modal"
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12">
            <label htmlFor="name">Name</label>
            <InputText value={name} onChange={(e) => setName(e.target.value)} id="name" type="text" />
          </div>
          <div className="p-field p-col-12">
            <label htmlFor="description">Description</label>
            <InputTextarea
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="lead-institution">Leading Institution <span className="required">*</span></label>
            <Dropdown
              id="lead-institution"
              value={leadInstitutionId}
              optionLabel="name"
              optionValue="id"
              options={INSTITUTIONS}
              onChange={(e) => setLeadInstitutionId(e.value)}
            />
          </div>
          <div className="p-field p-col-6">
            <label htmlFor="project">Assign to Project <span className="required">*</span></label>
            <Dropdown
              id="project"
              value={projectId}
              optionLabel="name"
              optionValue="id"
              options={PROJECTS}
              onChange={(e) => setProjectId(e.value)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateActivityModal;