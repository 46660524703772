// react imports
import React, { useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext'

/**
 * Properties:
 * @param dialogVisible: bool
 * @param onClose: () => {}
 * @param onCreate: (:string) => {}
 */
const InstitutionTypeDialog = (props) => {
  // states
  const [institutionType, setInstitutionType] = useState('');

  // events
  const createClickHandler = () => {
    props.onCreate(institutionType);
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label="Create" onClick={createClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header="Create Institution Type"
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="new-institution-type">Institution Type</label>
            <InputText
              id="new-institution-type"
              type="text"
              value={institutionType}
              onChange={(e) => setInstitutionType(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InstitutionTypeDialog;