// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

// comsts
import { PROJECTS } from "../../../../apis/sampleData";

// style
import "./AssignToProjectModal.scss";

/**
 * Props properties:
 * @param dialogVisible: boolean
 * @param onClose: () => {}
 * @returns 
 */
const AssignToProjectModal = (props) => {

  // templates
  const footerTemplate = (
    <>
      <Button label="Add" onClick={props.onClose} />
      <Button label="Cancel" onClick={props.onClose} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header="Assign to Project"
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={props.onClose}
      footer={footerTemplate}
      dismissableMask={true}
      className="assign-to-project-modal"
    >
      <div className="nrtd--dialog-form">
        <div className="p-field">
          <label>In Projects</label>
          <div className="projects-assigned">
            <div className="project-name-item">
              {PROJECTS[0].name}
            </div>
            <div className="project-name-item">
              {PROJECTS[1].name}
            </div>
          </div>
        </div>
        <div className="p-fluid">
          < div className="p-field">
            <label htmlFor="project">Project</label>
            <Dropdown
              id="project"
              optionLabel="name"
              optionValue="id"
              options={PROJECTS}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AssignToProjectModal;