// react imports
import { useState } from "react";

// prime react imports
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';

// custom components
import MechanismReportingList from "./mechanism-reporting-list/MechanismReportingList";

// styles
import './Reporting.scss';
import { Button } from "primereact/button";

// consts
const UPR_REPORTING_DATA = [
  {
    title: 'State report',
    link: 'https://google.com'
  },
  {
    title: 'Mid-term report',
    link: 'https://google.com'
  }
];


const Reporting = () => {
  // states
  const [activeIndex, setActiveIndex] = useState(1);
  const [doDisplayPrevReportingCycles, setDoDisplayPrevReportingCycles] = useState(false);

  return (
    <div className="reporting-page page-wrapper white-bc">
      <div className="display-prev-cycles">
        <Checkbox
          inputId="display-prev-cycles"
          onChange={e => setDoDisplayPrevReportingCycles(e.checked)}
          checked={doDisplayPrevReportingCycles}
        ></Checkbox>

        <label htmlFor="display-prev-cycles"> Display previous reporting cycles </label>
      </div>

      <TabView className="big-blue" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Universal Periodic Review">
          <MechanismReportingList />
        </TabPanel>
        <TabPanel header="Treaty Bodies">
          <MechanismReportingList />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Reporting;