// react imports
import { useState } from "react";
import { useHistory } from "react-router-dom";

// primereact components
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { FileUpload } from "primereact/fileupload";

// custom components
import RecommendationList from "../../../recommendations/recommendation-list/RecommendationList";
import RecommendationsFilterPanel from "../../../recommendations/recommendations-filter-panel/RecommendationsFilterPanel";
import SelectedItems from "../../../common/selected-items/SelectedItems";
import ActivitiesFilterPanel from "../../../activities/activities-filter-panel/ActivitiesFilterPanel";
import ActivitiesList from "../../../activities/activities-list/ActivitiesList";

// sample data
import { ACTIVITIES } from "../../../../apis/sampleData";

// stylesFilterSelect
import "./NewProjectModal.scss";


/**
 * Props properties:
 * @param isOpen: boolean
 * @param setModalOpened: callback: boolean => {}
 */
const NewProjectModal = props => {
  const history = useHistory();

  // states
  const [newModalStep, setNewModalStep] = useState(1);
  const [step2SelectedTab, setStep2SelectedTab] = useState("Recommendations");
  const [selectedIcon, setSelectedIcon] = useState('');

  // new project
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [newProjectInstitutions, setNewProjectInstitutions] = useState([]);
  const [newProjectActionPlans, setNewProjectActionPlans] = useState([]);
  // const [newProjectAttachments, setNewProjectAttachments] = useState([]);
  // const [newProjectLinkedActivities, setNewProjectLinkedActivities] = useState([]);


  // helper functions
  const setBackToStep1 = () => {
    setTimeout(() => {
      setNewModalStep(1);
    }, 500);
  };
  const resetModalData = () => {
    setNewModalStep(1);

    setNewProjectTitle('');
    setNewProjectDescription('');
    setNewProjectInstitutions([]);
    setNewProjectActionPlans([]);
  };

  // events
  const onHideHandler = () => {
    resetModalData();

    setBackToStep1();

    props.setModalOpened(false)
  };
  const clickOnFooterBlueButton = () => {
    if (newModalStep === 1) {
      setNewModalStep(2);
      return;
    }

    onHideHandler();
  };

  // templates
  const newModalHeader = (
    <div className="new-project-header">
      <div className="new-project-modal-title">
        {newModalStep === 1 ? 'New Project' : 'Add recommendations and activities to the project'}
      </div>
      <div className="new-project-step">
        Step {newModalStep} of 2
      </div>
    </div>
  );
  const newModalFooter = (
    <>
      <button className="blue-button" onClick={clickOnFooterBlueButton}>
        {newModalStep === 1 ? 'Next' : 'Done'}
      </button>

      {newModalStep === 1 && (
        <button className="gray-button" onClick={onHideHandler}>
          Cancel
        </button>
      )}

      {newModalStep === 2 && (
        <div >
          <button className="gray-button" onClick={() => setNewModalStep(1)}>
            Back
          </button>

          <button className="gray-button" onClick={() => history.push('/projects/empty')}>
            Skip
          </button>
        </div>
      )}
    </>
  );
  const step1ModalContent = (
    <div className="new-project-modal-step-1">
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="project-title">Project title *</label>
          <InputText
            id="project-title"
            type="text"
            value={newProjectTitle}
            onChange={(e) => setNewProjectTitle(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            rows={5}
            cols={30}
            value={newProjectDescription}
            onChange={(e) => setNewProjectDescription(e.target.value)}
          />
        </div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col p-md-6">
            <label htmlFor="instition">Leading Institution</label>
            <MultiSelect
              id="instition"
              display="chip"
              optionLabel="name"
              value={newProjectInstitutions}
              options={[
                { name: 'New York', code: 'NY' },
                { name: 'Rome', code: 'RM' },
                { name: 'London', code: 'LDN' },
                { name: 'Istanbul', code: 'IST' },
                { name: 'Paris', code: 'PRS' }]
              }
              onChange={(e) => setNewProjectInstitutions(e.value)}
            />
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col">
            <label htmlFor="email">Attachments</label>
            <FileUpload
              name="attachment[]"
              url="./upload"
              multiple
              mode="basic"
              chooseLabel=""
              uploadLabel=""
              cancelLabel=""
            />
          </div>
          <div className="p-field p-col">
            <label>Choose icon</label>
            <div className="project-icons">

              <div
                className={`blue project-icon ${selectedIcon === 'las la-sitemap' ? 'selected' : ''}`}
                onClick={() => setSelectedIcon('las la-sitemap')}

              >
                <i className="las la-sitemap"></i>
              </div>

              <div
                className={`green project-icon ${selectedIcon === 'las la-network-wired' ? 'selected' : ''}`}
                onClick={() => setSelectedIcon('las la-network-wired')}
              >
                <i className="las la-network-wired"></i>
              </div>

              <div
                className={`yellow project-icon ${selectedIcon === 'las la-book' ? 'selected' : ''}`}
                onClick={() => setSelectedIcon('las la-book')}
              >
                <i className="las la-book"></i>
              </div>

              <div
                className={`red project-icon ${selectedIcon === 'las la-flag' ? 'selected' : ''}`}
                onClick={() => setSelectedIcon('las la-flag')}
              >
                <i className="lar la-flag"></i>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const step2ModalContent = (
    <div className="new-project-modal-step-2">
      <div className="project-items-tabs">
        <Button
          label="Recommendations"
          className={`p-button-text ${step2SelectedTab !== "Recommendations" && 'p-button-secondary'}`}
          badge="284"
          onClick={(e) => setStep2SelectedTab('Recommendations')}
        />

        <Button
          label="Activities"
          className={`p-button-text ${step2SelectedTab !== "Activities" && 'p-button-secondary'}`}
          badge="173"
          onClick={(e) => setStep2SelectedTab('Activities')}
        />
      </div>

      {step2SelectedTab === "Recommendations" && (
        <div className="step-2-recommendations-tab">
          <SelectedItems
            title="Selected Recommendations"
            selectedItems={[{ id: 0, name: "Elaboration of the legislative amendments" }, { id: 1, name: "Training on effective investigation of torture" },]}
            removeItem={id => console.log('remove item ' + id)}
          />

          <RecommendationsFilterPanel extended={false} />

          <RecommendationList
            recommendationListHeight={330}
            recommendationClickable={false}
            isLinkable={true}
            isLinkableToActivity={true}
            isMultipleAddingAvailable={true}
            multipleAddingLinkText="ADD SELECTED (#NUMBERS) TO PROJECT"
            isRecommendationsHighlightable={true}
          />
        </div>
      )}

      {step2SelectedTab === "Activities" && (
        <div className="step-2-activities-tab">
          <SelectedItems
            title="Selected activities"
            selectedItems={[{ id: 0, name: "Elaboration of the legislative amendments" }, { id: 1, name: "Training on effective investigation of torture" },]}
            removeItem={id => console.log('remove item ' + id)}
          />

          <ActivitiesFilterPanel />

          <ActivitiesList
            activities={ACTIVITIES}
            activitiesListHeight={330}
            sortBy="IMPLEMENTATION STATUS"
            multipleAddingAvailable={true}
            multipleAddingLinkText={"add (#NUMBERS) activity"}
            multipleAddingHandler={activityIDs => console.log("multiple add handler", activityIDs)}
            assignToProjectLinkAvailable={true}
            assigningToProjectHandler={id => console.log('assign to project')}
            removingFromProjectHandler={id => console.log('assign to project')}
          />
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      header={newModalHeader}
      footer={newModalFooter}
      visible={props.isOpen}
      onHide={onHideHandler}
      draggable={false}
      dismissableMask={true}
      className={`new-project-modal step-${newModalStep}`}
    >
      {newModalStep === 1 && step1ModalContent}
      {newModalStep === 2 && step2ModalContent}
    </Dialog>
  );
}

export default NewProjectModal;