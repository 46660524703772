// react imports
import { useState } from "react";

// primereact components
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

// components
import HomeCalendar from "./HomeCalendar/HomeCalendar";
import OverviewBar from '../common/overview-bar/OverviewBar';

// style
import "./Home.scss";

// helper consts
import { MONTHS } from '../../apis/constants';
import { NavLink } from "react-router-dom";
const INITIAL_MONTH_DATE = { year: new Date().getFullYear(), month: MONTHS[new Date().getMonth()] };
const DUMMY_MARKED_MONTHS = [
  { year: 2019, month: 'January' },
  { year: 2019, month: 'March' },
  { year: 2019, month: 'August' },
  { year: 2019, month: 'December' },
  { year: 2020, month: 'February' },
  { year: 2020, month: 'June' },
  { year: 2021, month: 'March' },
  { year: 2021, month: 'October' },
  { year: 2021, month: 'November' },
  { year: 2022, month: 'January' },
  { year: 2022, month: 'February' },
  { year: 2022, month: 'March' },
  { year: 2022, month: 'July' },
  { year: 2023, month: 'January' },
  { year: 2023, month: 'February' },
  { year: 2023, month: 'March' }
];

const Home = () => {
  // states
  const [selectedMonthDate, setSelectedMonthDate] = useState(INITIAL_MONTH_DATE);
  // const [markedMonths, _setMarkedMonths] = useState(DUMMY_MARKED_MONTHS);

  return (
    <div className="page-wrapper home">
      <div className="home-content">
        <h1>Welcome, Barry!</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

        <OverviewBar
          title="Projects overview"
          rightTopLink="/projects"
          rightTopLinkText="ALL PROJECTS"
          totalValue={17}
          barFillColor={"#106FA8"}
          barBackgroundColor={"#DBE9F2"}
          fillValue={4.4}
        />

        <OverviewBar
          title="Recommendations overview"
          rightTopLink="/recommendations"
          rightTopLinkText="ALL RECOMMENDATIONS"
          totalValue={17}
          barFillColor={"#FFAB2B"}
          barBackgroundColor={"#FCDEB0"}
          fillValue={4.4}
        />

        <OverviewBar
          title="Activities overview"
          rightTopLink="/activities"
          rightTopLinkText="ALL ACTIVITIES"
          totalValue={17}
          barFillColor={"#6DD230"}
          barBackgroundColor={"#D3F1C0"}
          fillValue={4.4}
        />
      </div>

      <div className="home-sidepanel">
        <h2>Calendar</h2>

        <Card>
          <HomeCalendar
            marked={DUMMY_MARKED_MONTHS}
            selected={selectedMonthDate}
            onSelectMonthDate={setSelectedMonthDate}
          />

          <hr />

          <div className="selected-month">
            <i className="las la-calendar-check"></i>
            {`${selectedMonthDate.month} ${selectedMonthDate.year}`}
          </div>

          <div className="home--sidepanel--list projects">
            <header>Projects</header>

            <div className="list-item">
              <NavLink className="list-item--title" to="/projects/2">
                Equality & non-discrimination
              </NavLink>
              <span className="list-item--event">UN upcoming review</span>
            </div>

            <div className="list-item">
              <NavLink className="list-item--title" to="/projects/2">
                Equality & non-discrimination
              </NavLink>
              <span className="list-item--event">UN upcoming review</span>
            </div>
          </div>

          <div className="home--sidepanel--list activities">
            <header>Activities</header>

            <div className="list-item">
              <NavLink className="list-item--title" to="/activities/2">
                Members of minorities
              </NavLink>
              <span className="list-item--event">Implementation deadline</span>
            </div>
          </div>

          <Button
            icon="las la-plus-circle"
            label="Create Event"
            className="p-button-text create-event"
            onClick={() => console.log('Create Event')}
          />
        </Card>
      </div>
    </div>
  );
};

export default Home;