export const getImplementationStatusTemplate = implementationStatus => {
  return (
    <div className="implementation-status-template">
      <div className={"status-bars " + implementationStatus.replace(/ /g, '-').toLowerCase()}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {implementationStatus}
    </div>
  );
};