// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

/**
 * Properties:
 * @param value: string
 * @param dialogVisible: bool
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (value) => {}
 * @param saveButtonLabel: string (default: Save)
 */
const TextInputDialog = (props) => {
  // states
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value])

  // events
  const saveClickHandler = () => {
    props.onSave(value);
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel ? props.saveButtonLabel : "Save"} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          < div className="p-field">
            <InputText value={value} onChange={(e) => setValue(e.target.value)} id="value" type="text" />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TextInputDialog;