// react imports
import { useState } from "react";
import { useHistory } from "react-router-dom";

// 3rd party components
import { Collapse } from "react-collapse";
import ReactTooltip from 'react-tooltip';

// primereact imports
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { DataView } from "primereact/dataview";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

// custom components
import CollapseTrigger from '../../common/collapse-trigger/CollapseTrigger';
import StatusIcon from "../../common/status-icon/StatusIcon";

// data imports
import { INSTITUTIONS, MECHANISMS } from "../../../apis/sampleData";
import { PAGINATOR_TEMPLATE } from '../../../apis/constants';

// styles
import "./MechanismReportingList.scss";


/**
 * Props properties:
 */
const MechanismReportingList = () => {
  let history = useHistory();

  // states
  const [mechanisms, setMechanisms] = useState(MECHANISMS);

  // helper functions
  const mechanismTitleClicked = ({mechanismId}) => {
    const newMechanisms = [...mechanisms];

    // get the mechanism object
    const mechanism = newMechanisms.find(m => m.id === mechanismId);

    // change the opened property
    mechanism.opened = !mechanism.opened;

    setMechanisms(newMechanisms);
  };

  
  // templates
  const statusCellTemplate = rowData => (
    <StatusIcon
      className={rowData.status.name.replaceAll(' ', '-').toLowerCase()}
      iconClassName={rowData.status.icon}
      color={rowData.status.color}
      tooltip={rowData.status.name}
    />
  );
  const institutionCellTemplate = rowData => (
    <div className="p-field">
      <Dropdown
        optionLabel="name"
        value={rowData.institution}
        options={INSTITUTIONS}
        onChange={(e) => console.log(e.value)}
        placeholder="Lead Institution"
      />
    </div>
  );
  const getButtonsCellTemplateFunc = mechanismId => report => (
    <div className="mechanism-buttons">
      <a className="blue-button" onClick={() => {history.push(`/reporting/report/${mechanismId}/${report.id}`);}}>
        EDIT REPORT
      </a>
      <a className="blue-button" target="_blank" rel="noreferrer">
        DOWNLOAD (.DOCX)
      </a>
    </div>
  );
  const mechanismRowTemplate = mechanism => (
    <div className="mechanism">
      <CollapseTrigger
        className="mechanism-title-row"
        opened={mechanism.opened}
        triggerPropertyObject={{ mechanismId: mechanism.id }}
        stateChanged={mechanismTitleClicked}
        arrowOnRight={true}
      >
        <div className="mechanism-title">
          {mechanism.name}
        </div>
        <StatusIcon 
          className={mechanism.status.name.replaceAll(' ', '-').toLowerCase()}
          iconClassName={mechanism.status.icon}
          color={mechanism.status.color}
          tooltip={mechanism.status.name}
        />
      </CollapseTrigger>
      <Collapse 
        isOpened={mechanism.opened} 
        theme={{ collapse: 'mechanism-collapse', content: 'mechanism-content' }}
      >
        
        <div className="property-field reporting-procedure">
          <div className="property-field-title">
            Reporting procedure
          </div>
          <div className="property-field-value">
            {mechanism.mechanismType} <i className="las la-question-circle" data-tip="Lorem ipsum"></i>
          </div>
        </div>

        <DataTable value={mechanism.requests}>
          <Column className="title" field="title" header="Request"></Column>
          <Column className="on-date" field="onDate" header="Request on"></Column>
          <Column className="by-date" field="byDate" header="Request by"></Column>
          <Column className="status" header="Status" body={statusCellTemplate}></Column>
          <Column className="institution" header="Institution" body={institutionCellTemplate}></Column>
          <Column className="buttons" body={getButtonsCellTemplateFunc(mechanism.id)}></Column>
        </DataTable>

        <div className="view-buttons">
          <Button
            icon="las la-th-list"
            label="View related recommendations"
            className="p-button-text"
            onClick={() => console.log("View related recommendations")}
          />

          <Button
            icon="las la-folder-open"
            label="View related documents"
            className="p-button-text"
            onClick={() => console.log('View related documents')}
          />
        </div>
      </Collapse>

      <ReactTooltip
        place="top"
        type="light"
        html={true}
        backgroundColor='#ECECEC'
        arrowColor='#ECECEC'
        color='black'
      />
    </div>
  );


  return (
    <div className="nrtd-list mechanism-reporting-list">
      <div className="p-dataview-content">
        <DataView 
          value={MECHANISMS} 
          layout="list" 
          itemTemplate={mechanismRowTemplate}
          paginator
          paginatorTemplate={PAGINATOR_TEMPLATE}
          rows={10}
        >

        </DataView>
      </div>

      {/* <AssignToProjectModal
        dialogVisible={projectDialogVisible}
        onClose={() => setProjectDialogVisible(false)}
        onSave={() => setProjectDialogVisible(false)}
      />

      <LinkActivityModal
        dialogVisible={activityDialogVisible}
        onClose={() => setActivityDialogVisible(false)}
        onSave={() => setActivityDialogVisible(false)}
      /> */}
    </div>
  );
};

export default MechanismReportingList;
