// react imports
import React, { useState } from "react";

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

// import custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import UserDialog from './user-dialog/UserDialog';

// data imports
import { PAGINATOR_TEMPLATE } from '../../../apis/constants';
import { USERS } from "../../../apis/sampleData";


const EMPTY_USER = { id: 0, role: '', institution: '', firstName: '', lastName: '', email: '', username: '', password: '', country: '' };



const UsersPage = () => {
  // new institution dialog state
  const [newUserDialogVisible, setNewUserDialogVisible] = useState(false);

  // edit institution dialog states
  const [editUserDialogVisible, setEditUserDialogVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(EMPTY_USER);

  // events
  const getUserActionClickHandler = (userId) => {
    return (actionName) => {
      switch (actionName) {
        case 'Edit':
          const userToEdit = USERS.find(i => i.id === userId);
          setEditingUser({
            id: userToEdit.id,
            role: userToEdit.role,
            institution: userToEdit.institution,
            language: userToEdit.language,
            firstName: userToEdit.firstName,
            lastName: userToEdit.lastName,
            email: userToEdit.email,
            username: userToEdit.username,
            password: userToEdit.password,
          });
          setEditUserDialogVisible(true);
          break;
        case 'Delete':
          const userToDelete = USERS.find(i => i.id === userId);
          confirmDialog({
            message: `Are you sure you want to delete ${userToDelete.firstName + ' ' + userToDelete.lastName}?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => { console.log('Deleted'); },
            reject: () => { }
          });
          break;
        default:
          break;
      }
    };
  };

  // templates
  const getActionMenuTemplate = rowData => {
    const actions = [
      { name: 'Edit', iconClass: "las la-edit" },
      { name: 'Delete', iconClass: "las la-trash" }
    ];

    return (
      <ActionMenu
        actions={actions}
        actionSelected={getUserActionClickHandler(rowData.id)}
      />
    );
  };


  const listedUsers = USERS.map(u => ({
    id: u.id,
    name: u.firstName + ' ' + u.lastName,
    role: u.role,
    country: u.country
  }));


  return (
    <div className="settings--tab-content">
      {/*<Editor style={{ height: '320px' }} placeholder="Additional information on implementation progress" />*/}

      <div className="settings--title-bar">
        <h1>Users</h1>
        <div className="settings--buttons">
          <Button
            icon="las la-th-list"
            label="Create User"
            className="p-button-text"
            onClick={() => setNewUserDialogVisible(true)}
          />
        </div>
      </div>

      <UserDialog
        user={EMPTY_USER}
        dialogVisible={newUserDialogVisible}
        saveButtonLabel="Create"
        dialogHeader="NEW USER"
        onClose={() => setNewUserDialogVisible(false)}
        onSave={(i, role, institution, language, firstName, lastName, email, username, password, country) => { setNewUserDialogVisible(false); }}
      />

      <UserDialog
        user={editingUser}
        dialogVisible={editUserDialogVisible}
        saveButtonLabel="Save"
        dialogHeader="EDIT USER"
        onClose={() => setEditUserDialogVisible(false)}
        onSave={(i, role, institution, language, firstName, lastName, email, username, password, country) => { setEditUserDialogVisible(false); }}
      />

      <DataTable
        value={listedUsers}
        paginator
        paginatorTemplate={PAGINATOR_TEMPLATE}
        rows={10}
        sortField="id"
        sortOrder={1}
      >
        <Column className="id" field="id" header="ID" sortable></Column>
        <Column className="name" field="name" header="Name" sortable></Column>
        <Column field="role" header="Role" sortable></Column>
        <Column field="country" header="Country" sortable></Column>
        <Column className="actions" header="Actions" body={getActionMenuTemplate}></Column>
      </DataTable>
    </div>
  );
};

export default UsersPage;