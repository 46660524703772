// react imports
import React, { useState } from "react";

// styles
import "./SettingsTab.scss"

/**
 * Properties:
 * @param initialActiveTabId: string (optional)
 * @returns 
 */
const SettingsTab = ({ children, initialActiveTabId }) => {
  initialActiveTabId = initialActiveTabId ? initialActiveTabId :
    ((children && children.length > 0) && children[0].props.id);

  const [activeTabId, setActiveTabId] = useState(initialActiveTabId);

  return (
    <div className="settings-tabs">
      {children.length === 0 ? <p><strong>There are no tabs provided</strong></p> : (
        <>
          <ul className="settings-tabs--tablist">
            {children.map(tab => (
              <li
                key={tab.props.id + '-tab-link'}
                onClick={() => setActiveTabId(tab.props.id)}
                className={tab.props.id === activeTabId ? "active" : ""}
              >
                <i className={tab.props.iconClassName}></i>
                <div className="title-subtitle">
                  <h3>{tab.props.title}</h3>
                  {tab.props.subtitle ? <h4>{tab.props.subtitle}</h4> : ''}
                </div>
              </li>
            ))}
          </ul>
          {children.map(tab => (
            <div
              key={tab.props.id}
              className={"settings-tab-content " + (tab.props.id === activeTabId && "active")}
            >
              {tab.props.children}
            </div>
          ))}
        </>
      )}
    </div >
  );
};

export default SettingsTab;