// react imports
import React from "react";

// custom components
import SettingsTab from "./settings-tab/SettingsTab";

// settings pages
import InstitutionsPage from "./institutions-page/Institutions-page";
import UsersPage from "./users-page/UsersPage";
import AffectedPersonsPage from './affected-persons-page/AffectedPersonsPage';
import MechanismsPage from './mechanisms-page/MechanismsPage';
import CurrenciesPage from './currencies-page/CurrenciesPage';
import ThemesPage from "./themes-page/ThemesPage";
import DocumentsPage from "./documents-page/DocumentsPage";

// styles
import "./Settings.scss";

const Settings = () => {

  return (
    <div className="settings page-wrapper white-bc">
      <SettingsTab>
        <div id="manage-users" title="Manage Users" subtitle="Lorem ipsum dolor" iconClassName="las la-users">
          <UsersPage />
        </div>
        <div id="affected-persons" title="Affected Persons" subtitle="Lorem ipsum dolor" iconClassName="las la-user-circle">
          <AffectedPersonsPage />
        </div>
        <div id="hr-mechanism" title="HR Mechanism" subtitle="Lorem ipsum dolor" iconClassName="las la-award">
          <MechanismsPage />
        </div>
        <div id="currencies" title="Currencies" subtitle="Lorem ipsum dolor" iconClassName="las la-dollar-sign">
          <CurrenciesPage />
        </div>
        <div id="institutions" title="Institutions" subtitle="Lorem ipsum dolor" iconClassName="las la-university">
          <InstitutionsPage />
        </div>
        <div id="themes" title="Themes" subtitle="Lorem ipsum dolor" iconClassName="las la-comment">
          <ThemesPage />
        </div>
        <div id="related-national-documents" title="Related national documents" subtitle="Lorem ipsum dolor" iconClassName="las la-folder-open">
          <DocumentsPage />
        </div>
      </SettingsTab>
    </div>
  );
};

export default Settings;