// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';

// custom components
import TextInputDialog from "../../../common/dialogs/text-input-dialog/TextInputDialog";
import TextAreaDialog from "../../../common/dialogs/textarea-dialog/TextAreaDialog";

// sample
import { ACTIVITIES, IMPLEMENTATION_STATUSES, INSTITUTIONS, CURRENCIES } from "../../../../apis/sampleData";
import { getImplementationStatusTemplate } from "../../../../apis/temlates";

// styles
import "./ActivityDetailsContent.scss"

/**
 * 
 * @param activity: ACTIVITY
 * @returns 
 */
const ActivityDetailsContent = (props) => {
  // states
  const [editNameDialogVisible, setEditNameDialogVisible] = useState(false);
  const [editDescriptionDialogVisible, setEditDescriptionDialogVisible] = useState(false);

  // templates
  const getEditDialogFooterTemplate = onClose => (
    <>
      <Button label="Save" onClick={onClose} />
      <Button label="Cancel" onClick={onClose} className="p-button-secondary" />
    </>
  );


  return (
    <div className="single-element-tab-content details-content">
      <p className="name inline-edit-block">
        <span>{props.activity.name}</span>
        <Button
          icon="las la-pencil-alt"
          className="p-button-text p-button-secondary"
          onClick={() => setEditNameDialogVisible(true)}
        />

        <TextInputDialog
          value={props.activity.name}
          dialogVisible={editNameDialogVisible}
          dialogHeader="Edit Title"
          onClose={() => setEditNameDialogVisible(false)}
          onSave={() => setEditNameDialogVisible(false)}
        />
      </p>

      <p className="description inline-edit-block">
        <span>{props.activity.description}</span>
        <Button
          icon="las la-pencil-alt"
          className="p-button-text p-button-secondary"
          onClick={() => setEditDescriptionDialogVisible(true)}
        />

        <TextAreaDialog
          value={props.activity.description}
          dialogVisible={editDescriptionDialogVisible}
          dialogHeader="Edit Description"
          onClose={() => setEditDescriptionDialogVisible(false)}
          onSave={() => setEditDescriptionDialogVisible(false)}
        />
      </p>

      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-6">
          <label htmlFor="implementation-status">Implementation Status</label>
          <Dropdown
            id="implementation-status"
            value={props.activity.implementationStatus}
            options={IMPLEMENTATION_STATUSES}
            valueTemplate={getImplementationStatusTemplate}
            itemTemplate={getImplementationStatusTemplate}
          />
        </div>
        <div className="p-field p-col-12">
          <Editor
            style={{ height: '160px' }}
            placeholder="Additional information on implementation progress"
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="lead-institution">Lead Institution</label>
          <Dropdown
            id="lead-institution"
            value={props.activity.leadInstitution.id}
            optionLabel="name"
            optionValue="id"
            options={INSTITUTIONS}
          />
        </div>
        <div className="p-field p-col-6 text-with-select">
          <label htmlFor="budget">Budget</label>
          <InputText value="150 000" />
          <Dropdown
            value={33}
            optionValue="id"
            optionLabel="name"
            options={CURRENCIES}
          />
        </div>
        <div className="p-field p-col-12 two-calendar">
          <label htmlFor="date">Date of completion</label>

          <Calendar
            onChange={(e) => console.log(e.value)}
            showIcon
            icon="lar la-calendar"
            placeholder="From date"
          />

          <Calendar
            onChange={(e) => console.log(e.value)}
            showIcon
            icon="lar la-calendar"
            placeholder="To date"
          />
        </div>
        <div className="p-field p-col-12">
          <Button
            label="Submit"
            onClick={() => console.log('Submit')}
            className="submit-button"
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityDetailsContent;