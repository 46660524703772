// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

// comsts
import { PROJECTS } from "../../../../apis/sampleData";

// style
import "./AddActionPlanDialog.scss";

/**
 * Props properties:
 * @param dialogVisible: boolean
 * @param onClose: () => {}
 * @returns 
 */
const AddActionPlanDialog = (props) => {

  // templates
  const footerTemplate = (
    <>
      <Button label="Add" onClick={props.onClose} />
      <Button label="Cancel" onClick={props.onClose} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header="Add Action Plan"
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={props.onClose}
      footer={footerTemplate}
      dismissableMask={true}
      className="add-action-plan-modal"
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          < div className="p-field">
            <label htmlFor="action-plan">Action Plan</label>
            <Dropdown
              id="action-plan"
              options={['Gender Equality Action Plan', 'National Poverty Strategy']}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddActionPlanDialog;